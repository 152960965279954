import commons from './commons'
import brands from './brands'
import hotmart from './hotmart'
import klickpages from './klickpages'

export default {
  ...commons,
  ...brands,
  ...hotmart,
  ...klickpages,
}
