import navigate from '~/mixins/navigate'

export default {
  mixins: [navigate],

  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    type: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'secondary', 'tertiary'].includes(prop),
    },

    navigateOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    typeClasses() {
      return `link--${this.type}`
    },
  },
}
