export default {
  props: {
    src: {
      type: String,
      required: true,
    },

    width: {
      type: String,
    },

    height: {
      type: String,
    },

    alt: {
      type: String,
    },

    title: {
      type: String,
    },

    transparent: {
      type: Boolean,
      default: false,
    },

    withoutLazyload: {
      type: Boolean,
      default: false,
    },

    aspectRatio: {
      type: String,
      default: '',
    },

    srcsetDesktop: {
      type: String,
    },

    srcsetTablet: {
      type: String,
    },

    srcsetMobile: {
      type: String,
    },

    srcsetDesktopWebp: {
      type: String,
    },

    srcsetTabletWebp: {
      type: String,
    },

    srcsetMobileWebp: {
      type: String,
    },
  },

  computed: {
    transparentClasses() {
      if (this.transparent) return 'image--transparent'
    },

    aspectRatioClasses() {
      if (this.aspectRatio) return `aspect-ratio--${this.aspectRatio}`
    },
  },
}
