// MODULES
import { getMetaTags } from './modules/meta'
import { getLinks } from './modules/link'
import { getScripts } from './modules/script'

// SCHEMA MODULES
import { setSchemaWebpage } from './modules/schema/modules/webpage'
import { setSchemaVideo } from './modules/schema/modules/video'
import { setSchemaBreadcrumb } from './modules/schema/modules/breadcrumbs'
import { setSchemaFaq } from './modules/schema/modules/faq'
import { setSchemaOrganization } from './modules/schema/modules/organization'

// VALIDATORS
import { routeNameIsLocalized } from './validations'

const useComposeHeadTag = payload => {
  const { title, locale } = payload

  if (!routeNameIsLocalized({ ...payload })) return {}

  const pageHead = {
    htmlAttrs: {
      lang: locale,
    },
  }

  if (title) pageHead.title = title
  pageHead.meta = getMetaTags({ ...payload })
  pageHead.link = getLinks({ ...payload })
  pageHead.script = getScripts({ ...payload })

  return pageHead
}

export default {
  useComposeHeadTag,
  setSchemaOrganization,
  setSchemaWebpage,
  setSchemaVideo,
  setSchemaBreadcrumb,
  setSchemaFaq,
}
