export const setSchema = payload => {
  const { json } = payload

  const newSchemaObj = {
    type: 'application/ld+json',
    json,
    body: true,
  }

  return newSchemaObj
}
