import { logError } from '~/utils/log'

const ImageValidator = (prop, name) => {
  const hasImage = prop.hasOwnProperty('image')

  if (!hasImage) {
    logError(`The prop "${name}" needs to receive an image object.`, prop)

    return false
  }

  const hasImageSrc = prop.image.hasOwnProperty('src')
  const isValidImageSrc = typeof prop.image.src === 'string' ? true : false
  const hasValidImageObject = hasImageSrc && isValidImageSrc

  if (!hasValidImageObject) {
    logError(`The image object for ${name} is not valid because it doesn't have the "src" attribute.`, prop)

    return false
  }

  return true
}

export default ImageValidator
