export default {
  props: {
    tag: {
      type: String,
      default: 'span',
    },

    label: {
      type: String,
      default: '',
    },
  },

  render(createElement) {
    const tag = this.tag
    let attributes = {
      attrs: {
        class: ['title'],
      },
    }
    const slot = this.$slots.default

    if (this.label)
      attributes = {
        ...attributes,
        domProps: {
          innerHTML: this.label,
        },
      }

    return createElement(tag, attributes, slot)
  },
}
