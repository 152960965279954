const i18nLocales = [
  {
    name: 'Português - Brasil',
    code: 'pt-br',
    iso: 'pt-BR',
    file: 'pt-br.js',
  },
  {
    name: 'English',
    code: 'en',
    iso: 'en',
    file: 'en.js',
  },
  {
    name: 'Español',
    code: 'es',
    iso: 'es',
    file: 'es.js',
  },
  {
    name: 'Español - España',
    code: 'es-es',
    iso: 'es-ES',
    file: 'es-es.js',
  },
  {
    name: 'Español - Colombia',
    code: 'es-co',
    iso: 'es-CO',
    file: 'es-co.js',
  },
  {
    name: 'Español - México',
    code: 'es-mx',
    iso: 'es-MX',
    file: 'es-mx.js',
  },
  {
    name: 'Français',
    code: 'fr',
    iso: 'fr',
    file: 'fr.js',
  },
  {
    name: '日本語',
    code: 'ja',
    iso: 'ja',
    file: 'jp.js',
  },
  {
    name: 'Italiano',
    code: 'it',
    iso: 'it',
    file: 'it.js',
  },
]

export default i18nLocales
