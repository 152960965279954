export const setSchemaWebpage = payload => {
  const { locale, url, name, description } = payload
  const schema = {
    '@type': 'WebPage',
    url,
    name,
    description,
    inLanguage: locale,
  }

  return schema
}
