import hotIcon from '~/components/Molecules/Icon/Icon.vue'

export default {
  components: {
    hotIcon,
  },

  props: {
    submenu: {
      type: [Array, Object],
      required: true,
    },

    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    closeDropdown() {
      this.$emit('close-dropdown')
    },
  },
}
