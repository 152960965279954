
import Provider from '~/services/provider'

export default {
  data() {
    return {}
  },

  computed: {
    hasLocale() {
      let parameters = this.$route.fullPath

      return Boolean(
        ['pt-br', 'es', 'es-co', 'es-es', 'es-mx', 'ja', 'en', 'it', 'de', 'fr'].some(substring =>
          parameters.includes(substring)
        )
      )
    },
    isHomepage() {
      return Boolean(this.$route.name && this.$route.name.includes('index'))
    },

    breadcrumbs() {
      const payload = {
        routePath: this.$route.path,
        dinamicValue: this.$route.name && this.$route.name.includes('tour-id') ? 'Video' : false,
      }

      const list = Provider.breadcrumbs.getItemList(payload)

      return list
    },
  },
}
