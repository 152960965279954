import home from './home.json'
import producers from './producers.json'
import affiliates from './affiliates.json'
import solutions from './solutions.json'
import hotmartApp from './hotmartApp.json'
import tour from './tour.json'
import payments from './payments.json'
import legal from './legal.json'
import membersArea from './membersArea.json'
import pricing from './pricing.json'
import about from './about.json'
import newSolutions from './newSolutions.json'
import newMembersArea from './newMembersArea.json'

export default {
  home,
  producers,
  affiliates,
  solutions,
  hotmartApp,
  tour,
  payments,
  legal,
  membersArea,
  pricing,
  about,
  newSolutions,
  newMembersArea,
}
