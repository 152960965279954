// SETTERS
import { setAlternate } from '../../../setters/modules/alternate'

export const setAlternateOG = payload => {
  const { routeName, locale = 'pt-BR' } = payload

  return [
    ...setAlternate({
      routeName,
      locale,
      callback: payload => {
        const { code, iso } = payload

        return {
          hid: `og:locale:alternate-${code}`,
          name: 'og:locale:alternate',
          content: iso,
        }
      },
    }),
  ]
}
