
import Library from './Library'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faTasks,
  faBars,
  faMouse,
  faShoppingCart,
  faUsdCircle,
  faCog,
  faLockAlt,
  faMailBulk,
  faSyncAlt,
  faCalendar,
  faStar,
  faCreditCardBlank,
  faWifiSlash,
  faCheckCircle,
  faVideo,
  faGift,
  faPercent,
  faBriefcase,
  faBullhorn,
  faChartNetwork,
  faBan,
  faTimesSquare,
  faAngleDown,
  faBorderAll,
  faWindow,
  faDollarSign,
  faTv,
  faComments,
  faSquare,
  faQuestion,
  faBook,
  faBallot,
  faShield,
  faBell,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faHomeLg,
  faHandshakeAlt,
  faCaretDown,
  faQuoteLeft,
  faCheck as fasCheck,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faTshirt,
  faCheck,
  faMegaphone,
  faGraduationCap,
  faChartLine,
  faUserPlus,
  faBookmark,
  faCreditCard,
  faBrowser,
  faFilter,
  faUserChart,
  faLink,
  faRunning,
  faBabyCarriage,
  faDesktopAlt,
  faMousePointer,
  faPie,
  faDog,
  faPencil,
  faBasketballBall,
  faCalendarCheck,
  faUsers,
  faPlayCircle,
  faBookAlt,
  faBoxOpen,
  faCameraAlt,
  faPaintBrush,
  faTh,
  faCube,
  faTimes,
  faStars,
  faMoneyBill,
  faChartBar,
  faCommentsAlt,
  faMobile,
  faLaptop,
  faPlay,
  faAnalytics,
  faClock,
  faUser,
  faCircleQuestion,
  faHandHoldingDollar,
  faChartLineUp,
  faClapperboardPlay,
  faHandHoldingBox,
  faEnvelope,
} from '@fortawesome/pro-light-svg-icons'

library.add(faTshirt)
library.add(faCheck)
library.add(faMegaphone)
library.add(faGraduationCap)
library.add(faChartLine)
library.add(faUserPlus)
library.add(faBookmark)
library.add(faCreditCard)
library.add(faBrowser)
library.add(faFilter)
library.add(faUserChart)
library.add(faLink)
library.add(faRunning)
library.add(faBabyCarriage)
library.add(faDesktopAlt)
library.add(faMousePointer)
library.add(faPie)
library.add(faDog)
library.add(faPencil)
library.add(faBasketballBall)
library.add(faCalendarCheck)
library.add(faUsers)
library.add(faPlayCircle)
library.add(faBookAlt)
library.add(faBoxOpen)
library.add(faCameraAlt)
library.add(faPaintBrush)
library.add(faTh)
library.add(faCube)
library.add(faTimes)
library.add(faTasks)
library.add(faBars)
library.add(faShoppingCart)
library.add(faUsdCircle)
library.add(faCog)
library.add(faHomeLg)
library.add(faPlay)
library.add(faHandshakeAlt)
library.add(faMailBulk)
library.add(faLockAlt)
library.add(faStars)
library.add(faStar)
library.add(faSyncAlt)
library.add(faCreditCardBlank)
library.add(faCalendar)
library.add(faMoneyBill)
library.add(faChartBar)
library.add(faWifiSlash)
library.add(faCommentsAlt)
library.add(faMobile)
library.add(faLaptop)
library.add(faPlay)
library.add(faCheckCircle)
library.add(faVideo)
library.add(faGift)
library.add(faPercent)
library.add(faBriefcase)
library.add(faBullhorn)
library.add(faChartNetwork)
library.add(faBan)
library.add(faAnalytics)
library.add(faClock)
library.add(faUser)
library.add(faCaretDown)
library.add(faMouse)
library.add(faTimesSquare)
library.add(faAngleDown)
library.add(faBorderAll)
library.add(faWindow)
library.add(faDollarSign)
library.add(faTv)
library.add(faComments)
library.add(faSquare)
library.add(faQuestion)
library.add(faBook)
library.add(faBallot)
library.add(faShield)
library.add(faBell)
library.add(faEnvelope)
library.add(faCircleQuestion)
library.add(faHandHoldingDollar)
library.add(faChartLineUp)
library.add(faClapperboardPlay)
library.add(faHandHoldingBox)
library.add(fasCheck)
library.add(faQuoteLeft)

config.autoAddCss = false
export default {
  props: {
    name: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    fontAwesome: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    FontAwesomeIcon,
  },

  data() {
    return {
      icon: null,
    }
  },

  computed: {
    isFontAwesomeIcon() {
      return this.fontAwesome.length === 0 ? false : true
    },

    fontAwesomeIcon() {
      const icon = this.fontAwesome

      return icon
    },
  },

  created() {
    if (this.isFontAwesomeIcon) this.icon = this.fontAwesomeIcon
    else this.icon = Library[this.name]
  },
}
