const setGoogleFont = payload => {
  const { fontFamily, fontWeight } = payload

  return [
    {
      rel: 'preconnect',
      href: `https://fonts.googleapis.com`,
    },
    {
      rel: 'preconnect',
      href: `https://fonts.gstatic.com`,
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: `https://fonts.googleapis.com/css?family=${fontFamily}:${fontWeight}&display=swap`,
      as: 'style',
    },
    {
      href: `https://fonts.googleapis.com/css?family=${fontFamily}:${fontWeight}&display=swap`,
      rel: 'stylesheet',
      type: 'text/css',
    },
  ]
}

const setAdobeFont = payload => {
  return [
    {
      rel: 'preconnect',
      href: `https://use.typekit.net`,
    },
    {
      rel: 'preconnect',
      href: `https://use.typekit.net`,
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: `https://use.typekit.net/vfs6hcb.css`,
      as: 'style',
    },
    {
      href: 'https://use.typekit.net/vfs6hcb.css',
      rel: 'stylesheet',
      type: 'text/css',
    },
  ]
}

export const setFont = payload => {
  const { lib = 'Google' } = payload

  switch (lib) {
    case 'Google':
      return [...setGoogleFont({ ...payload })]
      break

    case 'Adobe':
      return [...setAdobeFont({ ...payload })]
      break

    default:
      return [...setGoogleFont({ ...payload })]
      break
  }
}
