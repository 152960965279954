import debounce from 'lodash.debounce'
import { documentWidth, outerWidth } from '~/utils/sizes'

export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resize)
      window.dispatchEvent(new Event('resize'))
      window.addEventListener('click', this.close)
    })
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.keyup)
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('click', this.close)
  },

  methods: {
    resize(e) {
      this.resizeDebounce(e, this)
    },

    resizeDebounce: debounce((e, self) => {
      const socialIcons = self.$refs.socialIcon

      socialIcons.forEach(socialIcon => {
        socialIcon.classList.remove('social-icon__links--open')
        socialIcon.classList.remove('social-icon__links--open-left')
        socialIcon.classList.remove('social-icon__links--open-right')
      })
    }, 100),

    toggleLinks(e) {
      const clickedSocialItem = e.currentTarget
      const socialIcons = this.$refs.socialIcon
      const screenWidth = documentWidth()

      const socialIconsWithoutClickedSocialIcon = [...socialIcons].filter(
        socialIcon => socialIcon !== clickedSocialItem
      )

      socialIconsWithoutClickedSocialIcon.forEach(socialIcon => {
        socialIcon.classList.remove('social-icon__links--open')
        socialIcon.classList.remove('social-icon__links--open-left')
        socialIcon.classList.remove('social-icon__links--open-right')
      })

      const clickedSocialItemPositionX = clickedSocialItem.getBoundingClientRect().x
      const clickedSocialItemLinks = clickedSocialItem.querySelector('.social-icons__links')

      if (clickedSocialItemLinks) {
        clickedSocialItem.classList.toggle('social-icon__links--open')

        const clickedSocialItemsPosition =
          screenWidth - (clickedSocialItemPositionX + outerWidth(clickedSocialItemLinks))

        if (clickedSocialItem.classList.contains('social-icon__links--open')) {
          if (clickedSocialItemsPosition <= 0) {
            clickedSocialItem.classList.add('social-icon__links--open-right')
          } else {
            clickedSocialItem.classList.add('social-icon__links--open-left')
          }
        } else {
          clickedSocialItem.classList.remove('social-icon__links--open-left')
          clickedSocialItem.classList.remove('social-icon__links--open-right')
        }
      }

      this.clearTextSelection()
    },

    clearTextSelection() {
      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        let elements = document.getElementsByClassName('social-icons__item')

        Object.values(elements).forEach(element => {
          element.classList.remove('social-icon__links--open')
          element.classList.remove('social-icon__links--open-left')
          element.classList.remove('social-icon__links--open-right')
        })
      }
    },
  },
}
