import { logError } from '~/utils/log'
import { ImageValidator } from '~/validators'

const VideoValidator = (prop, name) => {
  const hasVideo = prop.hasOwnProperty('video')

  if (!hasVideo) {
    logError(`The prop "${name}" needs to receive an video object.`, prop)

    return false
  }

  const isValidVideoYoutubeId = !!prop.video?.youtubeId
  const isValidVideoCover = ImageValidator(prop.video?.cover ?? {})

  if (!isValidVideoYoutubeId) {
    logError(`The video object for ${name} is not valid because it doesn't have the "youtubeId" attribute.`, prop)

    return false
  }

  if (!isValidVideoCover) {
    logError(
      `The video object for ${name} is not valid because it doesn't have the "cover" attribute with valid image object.`,
      prop
    )

    return false
  }

  return true
}

export default VideoValidator
