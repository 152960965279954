const setLink = payload => {
  const list = []

  for (const [key, value] of Object.entries(payload)) {
    const newLink = {
      rel: key,
      href: value,
    }
    if (key === 'mask-icon') newLink.color = '#f04e23'
    list.push(newLink)
  }

  return list
}

const setGroupLink = payload => {
  const { sharedAttributes, dinamicAttributes, group } = payload
  const list = []

  for (const [key, value] of Object.entries(group)) {
    const newLink = {
      ...sharedAttributes,
      [dinamicAttributes.key]: key,
      [dinamicAttributes.value]: value,
    }
    list.push(newLink)
  }

  return list
}

export { setLink, setGroupLink }
