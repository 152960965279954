export const setSchemaOrganization = payload => {
  const { description, telephone, email, address } = payload

  const locations = [
    {
      '@type': 'Place',
      name: 'Hotmart Brasil - Belo Horizonte',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Avenida Assis Chateaubriand, nº 499, Bairro Floresta',
        addressLocality: 'Belo Horizonte',
        addressRegion: 'Minas Gerais',
        postalCode: '30150-101',
        addressCountry: 'BR',
      },
    },

    {
      '@type': 'Place',
      name: 'Hotmart Brasil - São Paulo',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Av Engenheiro Luiz Carlos Berrini,105 - 7º Andar - Conj 701 B - Ed. Thera Corporate',
        addressLocality: 'São Paulo',
        addressRegion: 'São Paulo',
        postalCode: '04571-010',
        addressCountry: 'BR',
      },
    },
    {
      '@type': 'Place',
      name: 'Hotmart Colombia',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'El edificio 14.97, Park centro Empresarial, ubicado en la calle 97 A #13 a 57',
        addressLocality: 'Bogotá',
        addressRegion: 'Bogotá, D.C',
        postalCode: '110221',
        addressCountry: 'CO',
      },
    },
    {
      '@type': 'Place',
      name: 'Hotmart México',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Avenida Paseo de la Reforma, 342 Piso 25, Torre NYL, 6600',
        addressLocality: 'Ciudad de México',
        addressRegion: 'Cuauhtémoc',
        postalCode: '06030',
        addressCountry: 'MX',
      },
    },
    {
      '@type': 'Place',
      name: 'Hotmart Amsterdam',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Frederiksplein 1, 1017 XK - Amsterdam',
        addressLocality: 'Amsterdam',
        addressRegion: '',
        postalCode: '',
        addressCountry: 'NLD',
      },
    },
  ]

  const logoImage = {
    '@type': 'ImageObject',
    contentUrl: 'https://hotmart.com/static/app-hotmart/images/hotmart-logo.svg',
    name: 'Hotmart logo',
  }

  const schema = {
    '@type': 'Organization',
    name: 'Hotmart',
    url: 'https://hotmart.com',
    description,
    telephone,
    email,
    logo: logoImage,
    image: logoImage.contentUrl,
    address,
    //locations,
  }

  return schema
}
