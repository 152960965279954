const isRouteName = payload => {
  const { name } = payload

  return name.includes('___') ? true : false
}

const getNameExceptionMap = () => [
  {
    route: 'members-area',
    pageName: 'members-area/index',
  },
  {
    route: 'members-area-thank-you',
    pageName: 'members-area/thank-you',
  },
  {
    route: 'tour',
    pageName: 'tour/index',
  },
  {
    route: 'tour-id',
    pageName: 'tour/_id',
  },
]

const convertRouteNameToPageName = payload => {
  const { name } = payload

  const route = name.split('___')[0]

  let result
  result = route

  const exceptions = [...getNameExceptionMap()]

  const getException = exceptions.find(exception => exception.route === route)

  if (getException) {
    result = getException.pageName
  }

  return result
}

const convertPageNameToRouteName = payload => {
  const { name, locale } = payload

  let result
  result = name

  const exceptions = [...getNameExceptionMap()]

  const getException = exceptions.find(exception => exception.pageName === name)

  if (getException) {
    result = getException.route
  }

  return `${result}___${locale}`
}

const toggleNameFormat = payload => {
  return isRouteName({ ...payload })
    ? convertRouteNameToPageName({ ...payload })
    : convertPageNameToRouteName({ ...payload })
}

export { toggleNameFormat }
