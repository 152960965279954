import header from '~/mixins/header.js'
import modalSignup from '~/mixins/modalSignup.js'
import stripe from '~/mixins/stripe.js'
import debounce from 'lodash.debounce'
import supersonicTopStripe from '~/components/Supersonic/TopStripe/TopStripe.vue'
import hotOrganicTopStripe from '~/components/Molecules/Organic/TopStripe/TopStripe.vue'
import hotOrganicMenu from '~/components/Organisms/Organic/Menu/Menu.vue'
import hotSubMenu from '~/components/Molecules/SubMenu/SubMenu.vue'
import hotIcon from '~/components/Molecules/Icon/Icon.vue'
import signupButton from '~/components/Molecules/OrganicSquad/SignupButton/SignupButton.vue'
import device from '~/mixins/device.js'

export default {
  mixins: [device, modalSignup, header, stripe],

  components: {
    supersonicTopStripe,
    signupButton,
    hotOrganicTopStripe,
    hotIcon,
    hotOrganicMenu,
    hotSubMenu,
  },

  props: {
    supersonicHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      canShowTopStripe: true,
      expLoginHeader: '0',
      hasTop: true,
      header: null,
      hero: null,
      previousScrollPositionY: 0,
      solid: false,
      unpinned: false,
      isOpenMenu: false,
      dropdownIsOpen: false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.header = document.querySelector('.header')
      this.page = document.querySelector('.body')

      let { y: previousScrollPositionY } = this.scrollPosition()
      this.previousScrollPositionY = previousScrollPositionY

      window.addEventListener('scroll', this.scroll, { capture: true, passive: true })
      window.dispatchEvent(new Event('scroll'))
    })
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll)
  },

  computed: {
    hasLogoLabel() {
      return !!this.navigation?.logo?.label
    },

    hasNotificationBar() {
      return this.navigation.notificationBar ? true : false
    },

    isCheckedLocaleAbTest() {
      return ['pt-br', 'es-es', 'es-co', 'es-mx'].includes(this.$i18n.locale)
    },

    isPortuguese() {
      return Boolean(['pt-br'].includes(this.$i18n.locale))
    },

    isTest() {
      return Boolean(this.$route.query.v === 'b')
    },

    stripeContent() {
      return this.$t('components.topStripe.test')
    },

    isSSOTestAB() {
      const test = this.$route.query['test']

      return Boolean(['pt-br'].includes(this.$i18n.locale) && test === 'os_sso')
    },

    hasLogoClass() {
      return this.navigation.logo.class ? this.navigation.logo.class : 'normal'
    },

    isHomepage() {
      return Boolean(this.$route.name && this.$route.name.includes('index'))
    },

    hasTopStripe() {
      return Boolean(this.topStripe)
    },

    isInverted() {
      return false
    },

    isMobileCta() {
      return !this.isDesktop && this.$i18n.locale === 'pt-br'
        ? 'Cadastre-se grátis'
        : this.navigation.plattaform.signUp.label
    },

    platformLinks() {
      const plattaform = this.$t('components.menuOrganic.plattaform')

      return plattaform
    },
  },

  methods: {
    isAnchor(href) {
      return /^(https?:\/\/|\/\/)/.test(href) && /#[\w-]+/.test(href)
    },

    openMenu(e) {
      e.preventDefault()
      document.body.classList.toggle('modal-open')

      this.isOpenMenu = !this.isOpenMenu
    },

    scroll(e) {
      this.scrollDebounce(e, this)
    },

    scrollDebounce: debounce((e, self) => {
      const { y: currentScrollPositionY } = self.scrollPosition()

      if (self.isScrollDown() && self.isOutsideSafetyMarginHeader()) {
        self.unpinned = false
        self.solid = true

        if (self.headerDetached()) self.hasTop = false
        else self.hasTop = false
      }

      if (self.isScrollUp()) {
        self.unpinned = false
        if (self.isOutsideSafetyMarginHeader()) self.solid = true
        else self.solid = false

        if (self.headerDetached()) self.hasTop = false
        else self.hasTop = false
      }

      self.previousScrollPositionY = currentScrollPositionY
    }, 16),

    scrollPosition() {
      let scrollPositionY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const scrollMinY = 0
      const scrollMaxY =
        window.scrollMaxY || document.getElementById('__layout').scrollHeight - document.documentElement.clientHeight

      if (scrollPositionY < scrollMinY) scrollPositionY = scrollMinY
      if (scrollPositionY > scrollMaxY) scrollPositionY = scrollMaxY

      return {
        y: scrollPositionY,
      }
    },

    headerDetached() {
      const { y: currentScrollPositionY } = this.scrollPosition()

      return Boolean(currentScrollPositionY > 50)
    },

    isScrollDown() {
      const { y: currentScrollPositionY } = this.scrollPosition()
      const isScrollDown = this.previousScrollPositionY < currentScrollPositionY

      return isScrollDown
    },

    isScrollUp() {
      return !this.isScrollDown()
    },

    isOutsideSafetyMarginHeader() {
      const { height: headerHeight } = this.header.getBoundingClientRect()
      const { top: pageOffsetTop } = this.page.getBoundingClientRect()
      const headerSafetyMargin = headerHeight / 2

      return headerSafetyMargin < -pageOffsetTop
    },

    closeTopStripe() {
      this.canShowTopStripe = false
    },

    handleDropdownOpen(value) {
      this.dropdownIsOpen = value
    },

    closeOverlay() {
      this.dropdownIsOpen = false

      this.$refs.closeMenuOverlay.handleClose()
    },
  },
}
