export const getSchemaWebsite = payload => {
  const { locale } = payload

  const schema = {
    '@type': 'WebSite',
    url: 'https://hotmart.com',
    name: 'Hotmart',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://hotmart.com/s?q={search_term_string}&sck=HOTMART_SITE&src=GOOGLE_SEARCH',
      'query-input': 'required name=search_term_string',
    },
    sameAs: [
      'https://facebook.com/hotmartofficial',
      'https://twitter.com/hotmart',
      'https://www.linkedin.com/company/hotmart',
      'https://www.instagram.com/hotmart',
      'https://br.pinterest.com/hotmartofficial',
      'https://www.tiktok.com/@hotmart',
    ],
    inLanguage: locale,
  }

  return schema
}
