export default {
  props: {
    breadcrumbs: {
      type: [Object, Array],
      default: [],
    },
  },

  methods: {
    labelBread(payload) {
      if (payload) {
        const { index, crumb } = payload
        const label = index === 0 ? this.$t('components.breadcrumb.home.content') : crumb.content

        if (label) return decodeURIComponent(label)
      }
    },
  },
}
