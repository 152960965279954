export const setSharedGeneral = payload => {
  const { image, defaults } = payload
  const { images } = defaults

  const objToUpdate = {
    image: image ? image : images.general,
  }

  return {
    ...objToUpdate,
  }
}
