export const setDefaultAlternate = payload => {
  const { page } = payload
  const setHref = () => {
    let href, loc

    if (page.hasOwnProperty('pt-br')) {
      loc = 'pt-br'
    } else if (page.hasOwnProperty('en')) {
      loc = 'en'
    } else if (page.hasOwnProperty('es')) {
      loc = 'es'
    } else {
      loc = Object.keys(page)[0]
    }

    href = `https://hotmart.com/${loc}${page[loc]}`

    return href.slice(-1) === '/' ? href.slice(0, -1) : href
  }

  return {
    rel: 'alternate',
    href: setHref(),
    hreflang: 'x-default',
  }
}
