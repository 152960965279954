import en from '../data/en'
import enus from '../data/en-US'

import es from '../data/es'
import eses from '../data/es-ES'
import esco from '../data/es-CO'
import esmx from '../data/es-MX'

import fr from '../data/fr'

import ptbr from '../data/pt-BR'

const searchByLocale = locale => {
  const list = {
    en,
    enus,
    es,
    eses,
    esco,
    esmx,
    fr,
    ptbr,
  }

  return list[locale]
}

const filterResults = payload => {
  const { list = [], filters } = payload

  let filteredResults
  filteredResults = list

  const filterList = Object.keys(filters)

  for (const filterOption of filterList) {
    filteredResults = filteredResults.filter(
      item => item.hasOwnProperty(filterOption) && item[filterOption] === filters[filterOption]
    )
  }

  return filteredResults
}

export const getResult = payload => {
  const { locale, filters = null } = payload

  const parsedLocale = locale.replace('-', '').toLowerCase()

  const localizatedData = searchByLocale(parsedLocale)

  return filters ? filterResults({ list: localizatedData.data, filters }) : localizatedData.data
}

export default {
  getResult,
}
