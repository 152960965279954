const dictionary = {
  index: 'Home',

  // DEFAULT
  sparkle: 'Sparkle',
  tour: 'Tour',

  //PT-BR
  'sobre-nos': 'Sobre Nós',
  afiliados: 'Afiliados',
  comunidade: 'Comunidade',
  'area-de-membros': 'Área de Membros',
  'midias-pagas': 'Mídias Pagas',
  obrigado: 'Obrigado',
  pagamentos: 'Pagamentos',
  produtores: 'Produtores',
  'mapa-do-site': 'Mapa do Site',
  solucoes: 'Soluções',
  'app-da-hotmart': 'App da Hotmart',
  'acelerador-de-vendas': 'Acelerador de vendas',
  pacotes: 'Pacotes',
  'acelerador-de-vendas': 'Acelerador de Vendas',

  //EN
  about: 'About',
  affiliates: 'Affiliates',
  community: 'Community',
  'members-area': 'Members Area',
  'thank-you': 'Thank You',
  payments: 'Payments',
  pricing: 'Pricing',
  producers: 'Producers',
  solutions: 'Solutions',
  'is-hotmart-for-me': 'Is Hotmart for me?',
  'hotmart-app': 'Hotmart App',

  //ES
  sobre: 'Sobre',
  afiliados: 'Afiliados',
  comunidad: 'Comunidad',
  'area-de-miembros': 'Area de Miembros',
  gracias: 'Gracias',
  pagos: 'Pagos',
  productores: 'Productores',
  soluciones: 'Soluciones',
  precios: 'Precios',
  'app-de-hotmart': 'App de Hotmart',

  //FR
  'propos-de-nous': 'Propos de Nous',
  affilies: 'Affilies',
  'zone-des-membres': 'Zone des Membres',
  paiements: 'Paiements',
  prix: 'Prix',
  producteurs: 'Producteurs',

  //DR
  affiliates: 'Affiliates',
  features: 'Features',

  // IT
  affiliazione: 'Affiliazione',
  funzionalita: 'Funzionalità',
}

export default dictionary
