export const setCanonical = payload => {
  const { url } = payload

  const canonical = {
    rel: 'canonical',
    href: url,
  }

  return canonical
}
