import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      time: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      expired: true,
    }
  },

  props: {
    countdownEndDate: {
      type: String,
      required: true,
    },

    message: {
      type: String,
      required: true,
    },
  },

  mounted() {
    setInterval(this.countTime, 1000)
  },

  computed: {
    nameDays() {
      return this.time.days === 1 ? 'dia' : 'dias'
    },

    nameHours() {
      return this.time.hours === 1 ? 'hora' : 'horas'
    },

    nameMinutes() {
      return this.time.minutes === 1 ? 'minuto' : 'minutos'
    },

    nameSeconds() {
      return this.time.seconds === 1 ? 'segundo' : 'segundos'
    },
  },

  validations: {
    name: { required },
    email: { required },
    message: { required },
  },

  methods: {
    countTime() {
      const now = new Date().getTime()
      const distance = new Date(this.countdownEndDate).getTime() - now

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      this.time = {
        days,
        hours,
        minutes,
        seconds,
      }

      if (distance < 0) {
        clearInterval(this.countTime)
        this.expired = true
      }
    },
  },
}
