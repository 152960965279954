// MODULES
import { setSharedMeta } from './modules/shared.meta'
import { setSharedOG } from './modules/shared.og'
import { setAlternateOG } from './modules/shared.og.alternate'
import { setSharedTwitter } from './modules/shared.twitter'
import { setSharedGeneral } from './modules/shared.general'

// SETTERS
import { setMeta } from '../../setters'

const getDefaults = () => {
  const mainPath = 'https://hotmart.com/static/app-hotmart'

  const images = {
    general: `${mainPath}/images/share--general.jpg`,
    facebook: `${mainPath}/images/share--facebook.jpg`,
    twitter: `${mainPath}/images/share--twitter.jpg`,
  }

  return {
    images,
  }
}

const getSharedMetaTag = payload => [
  // Set meta by simple obj
  ...setMeta({
    ...setSharedMeta({ ...payload, defaults: { ...getDefaults() } }),
    ...setSharedTwitter({ ...payload, defaults: { ...getDefaults() } }),
    ...setSharedGeneral({ ...payload, defaults: { ...getDefaults() } }),
    ...setSharedOG({ ...payload, defaults: { ...getDefaults() } }),
  }),
]

const getMetaTags = payload => {
  const { meta = {} } = payload

  const shared = getSharedMetaTag({ ...payload })
  const page = setMeta({ ...meta })

  return [...shared, ...setAlternateOG({ ...payload, defaults: { ...getDefaults() } }), ...page]
}

export { getMetaTags }
