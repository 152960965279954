import modal from '~/mixins/modal.js'
import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'
import device from '~/mixins/device.js'

export default {
  mixins: [device, modal],

  components: {
    signupIframe: () => import('~/components/Molecules/OrganicSquad/SignupIframe/SignupIframe.vue'),
  },

  props: {
    dataModal: Object,
  },

  data() {
    return {
      dataLayer: {},
      deviceType: null,
      showFooter: true,
    }
  },

  head: {
    script: [{ src: '//embed.typeform.com/next/embed.js' }],
  },

  mounted() {
    createWidget(this.idTypeform, {
      container: document.querySelector('#typeform-iframe'), // you need an element with 'form' id
      width: '100%',
      height: '100%',
      inlineOnMobile: true,
      onStarted: () => {
        this.showFooter = false
      },
      onSubmit: ({ formId }) => {
        this.dataLayer = this.$nuxt.context.$gtm
        this.deviceType = !this.isDesktop ? 'mobile' : 'desktop'
        this.dataLayer.push({
          event: 'custom_event',
          custom_event_name: 'typeform_submit',
          event_details: `${this.deviceType}::${formId}`,
        })
      },
    })
  },

  methods: {
    sendGTM() {
      this.dataLayer = this.$nuxt.context.$gtm
      this.dataLayer.push({
        event: 'click_home_banner_sign_up_lp',
        event_category: 'CorporateWebSite:Home',
        event_action: `Click:SignUpLp:BannerHome`,
        event_label: 'BannerHome',
      })
    },

    closeModal(e) {
      document.body.style.overflow = 'initial'
      this.$modal.close(this.id)
    },
  },

  computed: {
    isSSO() {
      return this.data.type === 'sso'
    },

    isCampaignModal() {
      return this.$nuxt.context.query.v === 'b'
    },

    idTypeform() {
      const version = this.$nuxt.context.query.v ? `version_${this.$nuxt.context.query['v']}` : 'original'

      return !this.isDesktop ? this.data.typeform.id[version].mobile : this.data.typeform.id[version].desktop
    },

    embedTitle() {
      return this.isSSO ? this.data.sso.embed.title : this.data.typeform.embed.title
    },

    contentTitle() {
      return this.isSSO ? this.data.sso.content.title : this.data.typeform.content.title
    },

    contentSubtitle() {
      return this.isSSO ? this.data.sso.content.subtitle : this.data.typeform.content.subtitle
    },

    contentItems() {
      return this.isSSO ? this.data.sso.content.items : this.data.typeform.content.items
    },

    campaignImage() {
      return this.isCampaignModal ? this.data.typeform.campaign : ''
    },

    contentFooter() {
      return !this.isSSO && this.data.typeform.footer ? this.data.typeform.footer : ''
    },

    arrowImage() {
      return this.data.typeform.content.imageArrow ?? '/static/app-hotmart/images/signup-modal/item--arrow-down.svg'
    },
  },
}
