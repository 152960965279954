import { v4 as uuidv4 } from 'uuid'
import UAParser from 'ua-parser-js'
import crypto from 'crypto'

function extractUTMFromDataLayer(utmParameters) {
  const utmAttributes = {}
  if (typeof window !== 'undefined' && window?.dataLayer) {
    for (let i = 0; i < window.dataLayer.length; i++) {
      const layer = window.dataLayer[i]
      utmParameters.forEach(param => {
        if (layer[param]) {
          utmAttributes[param] = layer[param]
        }
      })
    }
  }

  return utmAttributes
}

function extractUTMFromURL(utmParameters, queryParams) {
  if (typeof window !== 'undefined') {
    const queryParamsObject =
      typeof queryParams === 'object' ? queryParams : Object.fromEntries(new URLSearchParams(window?.location?.search))
    const utmAttributes = {}
    utmParameters.forEach(param => {
      if (queryParamsObject[param]) {
        utmAttributes[param] = queryParamsObject[param]
      }
    })

    return utmAttributes
  } else {
    return {}
  }
}

function getUTMParameters(queryParams) {
  const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  let utmAttributes = extractUTMFromDataLayer(utmParameters)
  const utmURLAttributes = extractUTMFromURL(utmParameters, queryParams)
  utmParameters.forEach(param => {
    if (!utmAttributes[param] && utmURLAttributes[param]) {
      utmAttributes[param] = utmURLAttributes[param]
    }
  })

  return utmAttributes
}

function getDomainFromHost(host) {
  return host.split(':')[0]
}

function getDomainAndCookieOptions(isServer, req) {
  const domain = getDomainFromHost(isServer ? req.headers.host : window.location.hostname)
  const cookieOptions = {
    path: '/',
    maxAge: 60 * 60 * 24 * 365,
    sameSite: 'lax',
    domain: domain.includes('localhost') ? undefined : `.${domain}`,
  }

  return { domain, cookieOptions }
}

function setUserIdCookie(app, req, isServer) {
  let userId = app.$cookies.get('gb_uuid')
  if (!userId) {
    userId = uuidv4()
  }

  const { cookieOptions } = getDomainAndCookieOptions(isServer, req)

  app.$cookies.set('gb_uuid', userId, cookieOptions)

  return userId
}

/**
 * This cookie manages the cache from CloudFront CDN, it invalidate the cache when a experiment test changes on growthbook
 * --
 * Can be improved, this is used to bypass the CDN cache when feature changes
 * But it's looking at all features, so it's not very efficient, we can optimize it to use only experiments for the user
 * But, for now, getExperiments from growthbook don't sync with before render $growthbook.isOn to know which experiments are active
 * gb.getDeferredTrackingCalls() is a good option, but need to be executed after pages are processed on SSR.
 * @param context
 * @param growthbook
 */
export function setFeaturesCookie(context, growthbook) {
  const isServer = process.server
  if (isServer) {
    const { app, req } = context
    const { cookieOptions } = getDomainAndCookieOptions(isServer, req)
    const features = growthbook.getFeatures()
    const featuresHash = crypto.createHash('md5').update(JSON.stringify(features)).digest('hex')

    app.$cookies.set('ab-variant', featuresHash, cookieOptions)
  }
}

export const growthBookConfig = config => ({
  apiHost: config.PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: config.PUBLIC_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: config.enviroment === 'development' || config.enviroment === 'staging',
  debug: config.enviroment === 'development' || config.enviroment === 'staging',
  // URL Redirect Experiments on Growthbook. Be careful, this file serves both SSR and SPA. docs: https://docs.growthbook.io/lib/js#url-redirect-experiments
  disableUrlRedirectExperiments: true,
  streaming: process.server,
})

export const syncAttributes = (context, growthbook) => {
  const { app, req, res } = context
  const isServer = process.server

  const userId = setUserIdCookie(app, req, isServer)

  if (context.$config.enviroment === 'development' || context.$config.enviroment === 'staging') {
    console.log(`[GrowthBook] User ID: ${userId} - SSR: ${isServer}`)
  }

  const ua = process.server ? context.req?.headers['user-agent'] : navigator.userAgent
  const parser = new UAParser(ua)
  const result = parser.getResult()

  let path, queryParams, url, host

  if (process.server) {
    path = context.route.path
    queryParams = context.route.query
    host = context.req.headers.host
    url = `https://${host}${context.req.url}`
  } else {
    path = window.location.pathname
    queryParams = Object.fromEntries(new URLSearchParams(window.location.search))
    url = window.location.href
    host = window.location.hostname
  }

  const utmAttributes = getUTMParameters(queryParams)

  let attributes = {
    path: path,
    query: new URLSearchParams(queryParams).toString(),
    deviceType: result.device?.type || 'desktop',
    locale: app.i18n.locale,
    browser: result.browser?.name,
    ...utmAttributes,
    id: userId,
    url: url,
    host: host,
  }

  return growthbook.setAttributes({
    ...growthbook.getAttributes(),
    ...attributes,
  })
}
