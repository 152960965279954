import { mapGetters, mapActions } from 'vuex'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { hoverSupported } from '~/utils/hover'

export default {
  props: {
    navigation: {
      type: Object,
      required: true,
    },

    topStripe: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters('header', ['isSidebarOpen']),

    hasPlattaformSignInButton() {
      return !!this.navigation?.plattaform?.signIn
    },

    hasPlattaformSignUpButton() {
      return !!this.navigation?.plattaform?.signUp
    },

    hasPlattaformSignInSubMenu() {
      return !!this.navigation?.plattaform?.signInSubMenu
    },
  },

  methods: {
    ...mapActions('header', ['setSidebarOpen']),

    openSidebar(e) {
      e.preventDefault()

      this.setSidebarOpen(false)
    },

    closeSidebar(e) {
      e.preventDefault()

      this.setSidebarOpen(false)
    },

    toggleSidebar(e) {
      e.preventDefault()

      this.setSidebarOpen(!this.isSidebarOpen)
    },
  },

  watch: {
    isSidebarOpen() {
      if (!hoverSupported()) return

      if (this.isSidebarOpen) return disablePageScroll()

      return enablePageScroll()
    },
  },
}
