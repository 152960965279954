import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=7872ac0a&scoped=true"
import script from "./Footer.js?vue&type=script&lang=js&external"
export * from "./Footer.js?vue&type=script&lang=js&external"
import style0 from "./Footer.scss?vue&type=style&index=0&id=7872ac0a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7872ac0a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotLink: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Link/Link.vue').default,HotSocialIcons: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/SocialIcons/SocialIcons.vue').default,HotImage: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Image/Image.vue').default})
