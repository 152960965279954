export const checkAndThenAddProps = payload => {
  const { objects, props } = payload
  const { objToCheck, objToUpdate } = objects

  if (!objToCheck || !props) return {}

  const newObject = objToUpdate ? { ...objToUpdate } : { ...objToCheck }

  if (props.length > 0) {
    props.forEach(({ key, value }) => {
      if (!Object.keys(objToCheck).includes(key)) newObject[key] = value
    })
  }

  return newObject
}
