import header from '~/store/header'
import survey from '~/store/survey'
import growthbook from '~/store/growthbook'

const modules = {
  header,
  survey,
  growthbook,
}

export default {
  namespaced: true,
  actions: {
    async nuxtServerInit({ dispatch }, context) {
      await dispatch('growthbook/nuxtServerInit', context)
    },
  },
  modules,
}
