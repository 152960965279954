
import Provider from '~/services/provider'

export default {
  data() {
    return {}
  },

  computed: {
    breadcrumbs() {
      const payload = {
        routePath: this.$route.path,
        dinamicValue: false,
      }

      const list = Provider.breadcrumbs.getItemList(payload)

      return list
    },
  },
}
