import SignupModal from '~/components/Molecules/OrganicSquad/SignupModal/SignupModal.vue'

export default {
  props: {
    type: {
      type: String,
      default: 'sso',
    },

    link: {
      type: Object,
    },
  },

  methods: {
    openModal(e, data) {
      e.preventDefault()
      const modalOptions = {
        component: SignupModal,
        data: {
          locale: this.$i18n.locale,
          route: this.$route,
          hasLogo: false,
          sso: this.sso,
          typeform: this.typeform,
          type: this.type,
          device: this.device,
          showFooter: true,
          fullscreen: true,
        },
      }

      this.$modal.open(modalOptions)
    },
  },

  computed: {
    sso() {
      return this.$t('components.signupModal.sso')
    },

    typeform() {
      return this.$t('components.signupModal.typeform')
    },

    device() {
      return this.$route.query['device']
    },

    button() {
      if (this.type === 'sso') {
        return this.sso.gtm
      }

      return this.typeform.gtm
    },
  },
}
