export default {
  SET_SURVEY_EXTRAS(state, payload) {
    state.fields.extras.push(payload)
  },
  SET_SURVEY_EMAIL(state, payload) {
    state.fields.email = payload
  },
  SET_SURVEY_STEP(state, payload) {
    state.step = payload
  },
}
