import debounce from 'lodash.debounce'

export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {
      animationTimeout: null,
      currentItem: null,
      previousItem: null,
      isFirstLoad: false,
      timeout: 2500,
      counter: 0,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.countriesWrapper = document.querySelector('.made-with-love__countries')
      this.items = document.querySelectorAll('.made-with-love__country')
      this.underline = document.querySelector('.made-with-love__country-underline')

      this.setWidth()
      this.animate()

      window.addEventListener('resize', this.resize)
      window.addEventListener('orientationchange', this.resize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('orientationchange', this.resize)

    clearTimeout(this.animationTimeout)
  },

  methods: {
    resize(e) {
      this.resizeDebounce(e, this)
    },

    resizeDebounce: debounce((e, self) => {
      clearTimeout(self.animationTimeout)

      self.setWidth()
      self.animate()
    }, 100),

    animate() {
      clearTimeout(this.animationTimeout)

      this.animationTimeout = setTimeout(
        () => {
          this.isFirstLoad = true

          if (this.previousItem !== null) this.previousItem.classList.add('made-with-love__country--out')

          this.currentItem = this.countriesWrapper.children[this.counter]
          this.currentItem.classList.remove('made-with-love__country--out')
          this.currentItem.classList.add('made-with-love__country--in')

          this.underline.style.width = `${this.currentItem.clientWidth}px`

          this.previousItem = this.currentItem

          if (this.counter === this.items.length - 1) this.counter = 0
          else this.counter++

          this.animate()
        },
        this.isFirstLoad ? this.timeout : 0
      )
    },

    setWidth() {
      const itemsWidth = []

      this.items.forEach(item => itemsWidth.push(item.clientWidth))

      const biggestWidth = Math.max.apply(null, itemsWidth)

      if (this.currentItem) this.underline.style.width = `${this.currentItem.clientWidth}px`

      this.countriesWrapper.style.width = `${biggestWidth}px`
    },
  },
}
