import dropdown from '~/components/Organisms/Organic/Dropdown/Dropdown.vue'
import hotIcon from '~/components/Molecules/Icon/Icon.vue'

export default {
  components: {
    dropdown,
    hotIcon,
  },

  props: {
    navigation: {
      type: Array,
      required: false,
    },
    plattaform: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      openSubMenus: [],
    }
  },

  methods: {
    isAnchor(href) {
      return /^(https?:\/\/|\/\/)/.test(href) && /#[\w-]+/.test(href)
    },

    isOpen(index) {
      return this.openSubMenus.includes(index)
    },

    isActive(index) {
      return this.openSubMenus.some(i => i === index)
    },

    toggleDropdown(index) {
      if (this.isOpen(index)) {
        this.openSubMenus = []
        this.$emit('dropdown-open', false)
      } else {
        this.openSubMenus = [index]
        this.$emit('dropdown-open', true)
      }
    },

    handleClose() {
      this.openSubMenus = []
      this.$emit('dropdown-open', false)
    },
  },
}
