// DOCS: https://developers.google.com/search/docs/appearance/structured-data/faqpage?hl=pt-br
export const setSchemaFaq = payload => {
  const { items } = payload
  let mainEntity = []

  items.forEach(item => {
    mainEntity.push({
      '@type': 'Question',
      name: item.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.body,
      },
    })
  })

  const baseSchema = {
    '@type': 'FAQPage',
    mainEntity,
  }

  return baseSchema
}
