export default {
  async setSurveyExtras({ commit }, payload) {
    return commit('SET_SURVEY_EXTRAS', payload)
  },
  async setSurveyEmail({ commit }, payload) {
    return commit('SET_SURVEY_EMAIL', payload)
  },
  async setSurveyStep({ commit }, payload) {
    return commit('SET_SURVEY_STEP', payload)
  },
}
