import { v1 as uuidv1 } from 'uuid'

export default {
  data() {
    return {
      modals: [],
      defaultOptions: {
        id: null,
        component: null,
        data: null,
      },
    }
  },

  components: {
    hotModal: () => import('~/components/Molecules/Modal/Modal.vue'),
  },

  mounted() {
    window.addEventListener('keyup', this.keyup)
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.keyup)
  },

  methods: {
    open(options) {
      return new Promise(resolve => {
        document.body.classList.add('modal-open')

        this.$mount()

        const nuxtContainer = document.querySelector('#__nuxt')

        nuxtContainer.appendChild(this.$el)

        const modal = Object.assign({}, { ...this.defaultOptions, id: uuidv1() }, options)

        this.modals.push(modal)

        resolve(true)
      })
    },

    close(modalId) {
      return new Promise(resolve => {
        document.body.classList.remove('modal-open')

        this.remove(modalId)

        resolve(true)
      })
    },

    remove(modalId) {
      const modal = this.modals.filter(modal => modal.id === modalId)[0]

      if (modal) this.modals.splice(this.modals.indexOf(modal), 1)
    },

    keyup(e) {
      if (e.keyCode === 27 && this.modals.length > 0) {
        const lastOpenedModal = this.modals[this.modals.length - 1]

        this.close(lastOpenedModal.id)
      }
    },
  },
}
