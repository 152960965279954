export default {
  props: {
    id: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      default: () => {},
    },
  },
}
