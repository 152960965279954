// UTILITY
import { checkAndThenAddProps } from '../../../../../../utils/checkAndThenAddProps'

export const setSharedTwitter = payload => {
  const { url, image, title, description, meta = {}, defaults } = payload
  const { images } = defaults

  const objToUpdate = {
    'twitter:card': 'summary',
    'twitter:site': '@hotmart',
    'twitter:creator': '@hotmart',
    'twitter:url': url,
    'twitter:image': image ? image : images.twitter,
  }

  return {
    ...checkAndThenAddProps({
      objects: { objToCheck: meta, objToUpdate },
      props: [
        { key: 'twitter:title', value: title },
        { key: 'twitter:description', value: description },
      ],
    }),
  }
}
