// SETTERS
import { setAlternate } from '../../../../../setters/modules/alternate'

export const setVariantsAlternate = payload => {
  const { routeName, page, locale = 'pt-BR' } = payload

  return [
    ...setAlternate({
      routeName,
      locale,
      callback: payload => {
        const { code, iso } = payload

        const setHref = () => {
          const href = `https://hotmart.com/${code}${page[code]}`

          return href.slice(-1) === '/' ? href.slice(0, -1) : href
        }

        return {
          rel: `alternate`,
          href: setHref(),
          hreflang: iso,
        }
      },
    }),
  ]
}
