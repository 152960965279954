// Modules
import { getSchemaWebsite } from './modules/website'

const composeSchema = payload => {
  const { schema } = payload

  const composedObject = {
    '@context': 'https://schema.org',
    '@graph': [{ ...getSchemaWebsite({ ...payload }) }, ...schema],
  }

  return composedObject
}

export { composeSchema }
