import debounce from 'lodash.debounce'
import { parseLink } from '@/services/parselinkService'

export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {
      isOpen: false,
      localeName: '&nbsp;',
    }
  },

  computed: {
    getLocaleName() {
      const locale = this.availableLocales.find(locale => locale.code === this.getLocale)

      return locale.name
    },
    getLocale() {
      return this.$i18n.locale
    },
    availableLocales() {
      return this.$i18n.locales
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('keyup', this.keyup)
      window.addEventListener('resize', this.resize)
      window.dispatchEvent(new Event('resize'))

      this.localeName = this.getLocaleName

      this.localeItems = this.$refs.items
      if (!this.localeItems) {
        console.error('Locale items not found')

        return
      }

      this.amoutOfLocaleItems = this.localeItems.childElementCount
      this.firstLocaleItem = this.localeItems.firstChild
    })
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.keyup)
    window.removeEventListener('resize', this.resize)
  },

  methods: {
    resize(e) {
      this.resizeDebounce(e, this)
    },

    resizeDebounce: debounce((e, self) => {
      const localeItemHeight = self.firstLocaleItem.offsetHeight

      self.heightOfCloseItems = localeItemHeight
      self.heightOfOpenItems = self.amoutOfLocaleItems * localeItemHeight

      self.close()
    }, 100),

    open() {
      this.isOpen = this.availableLocales.length > 1
    },

    close() {
      this.isOpen = false
    },

    languageSwitchEvent(lang) {
      //console.log('You will change to language', lang)
      this.$gtm.push({
        event: 'custom_event',
        custom_event_name: 'click_switch_language',
        event_details: `Footer::${lang}`,
      })
    },

    keyup(e) {
      if (e.keyCode === 27 && this.isOpen) this.close()
    },

    parseLink(payload) {
      const { path, locale } = payload

      const validPath = path && path.trim() !== '' ? path : ''

      const route = locale ? `${this.switchLocalePath(locale)}` : validPath
      const newUrl = parseLink(route)

      if (!newUrl) {
        console.error('Failed to parse link for route:', route)

        return null
      }

      return newUrl
    },
  },
}
