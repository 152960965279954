import { getPages } from './modules/getPages'
import { toggleNameFormat } from './modules/toggleNameFormat'

const useRouteService = payload => {
  const { item } = payload

  let result

  switch (item) {
    case 'getPages':
      result = getPages({ ...payload })

      break
    case 'toggleNameFormat':
      result = toggleNameFormat({ ...payload })

      break
  }

  return result
}

export { useRouteService }
