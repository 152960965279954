export default function ({ route, app }) {
  const defaultMetaIndex = app.head.meta.findIndex(meta => meta.hid === 'robot-noindex')

  if (route.path === '/' || route.path === '') {
    if (defaultMetaIndex === -1) {
      app.head.meta.push({
        hid: 'robot-noindex',
        name: 'robots',
        content: 'noindex',
      })
    }
  }

  if (route.path !== '/' && route.path !== '') {
    if (defaultMetaIndex > -1) {
      app.head.meta.splice(defaultMetaIndex, 1)
    }
  }

  return
}
