export default {
  computed: {
    isMobile() {
      if (process.client && typeof navigator !== 'undefined' && typeof window !== 'undefined') {
        const userAgent = navigator.userAgent.toLowerCase() || window.opera.toLowerCase()

        return /android|blackberry|iphone|ipod|opera mini|iemobile|wpdesktop/i.test(userAgent)
      }

      return false
    },

    isTablet() {
      if (process.client && typeof navigator !== 'undefined' && typeof window !== 'undefined') {
        const userAgent = navigator.userAgent.toLowerCase() || window.opera.toLowerCase()

        return /ipad|android|android tablet|tablet|kindle|tab|nexus|playbook|kindle/i.test(userAgent)
      }

      return false
    },

    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
