const subdivideAbsoluteUrl = path => {
  const partials = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(path)

  return partials
}

const subdivideRelativeUrl = path => {
  const partials = path.startsWith('/') ? path.substring(1).split('/') : path.split('/')

  return partials
}

export default {
  subdivideAbsoluteUrl,
  subdivideRelativeUrl,
}
