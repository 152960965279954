import header from '~/mixins/header.js'
import debounce from 'lodash.debounce'
import supersonicTopStripe from '~/components/Supersonic/TopStripe/TopStripe.vue'
import hotSubMenu from '~/components/Molecules/SubMenu/SubMenu.vue'
import hotIcon from '~/components/Molecules/Icon/Icon.vue'

export default {
  components: {
    supersonicTopStripe,
    hotSubMenu,
    hotIcon,
  },

  mixins: [header],

  props: {
    supersonicHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      previousScrollPositionY: 0,
      header: null,
      hero: null,
      unpinned: false,
      solid: false,
      expLoginHeader: '0',
      hasTop: true,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.header = document.querySelector('.header')
      this.page = document.querySelector('.body')

      if (this.header && this.page) {
        let { y: previousScrollPositionY } = this.scrollPosition()
        this.previousScrollPositionY = previousScrollPositionY

        this.boundScroll = this.scroll.bind(this)
        window.addEventListener('scroll', this.boundScroll, { capture: true, passive: true })
        window.dispatchEvent(new Event('scroll'))
      } else {
        console.error('Header or page element not found')
      }
    })
  },

  beforeDestroy() {
    if (this.boundScroll) {
      window.removeEventListener('scroll', this.boundScroll)
    }
  },

  computed: {
    hasLogoLabel() {
      return !!this.navigation?.logo?.label
    },

    hasNotificationBar() {
      return this.navigation.notificationBar ? true : false
    },

    hasLogoClass() {
      return this.navigation.logo.class ? this.navigation.logo.class : 'normal'
    },

    isHomepage() {
      return Boolean(this.$route.name && this.$route.name.includes('index'))
    },

    hasTopStripe() {
      return Boolean(this.topStripe)
    },
  },

  methods: {
    isAnchor(href) {
      return /^(https?:\/\/|\/\/)/.test(href) && /#[\w-]+/.test(href)
    },

    scroll(e) {
      this.scrollDebounce(e, this)
    },

    scrollDebounce: debounce((e, self) => {
      const { y: currentScrollPositionY } = self.scrollPosition()

      if (self.isScrollDown() && self.isOutsideSafetyMarginHeader()) self.unpinned = true
      if (self.isScrollUp()) {
        self.unpinned = false
        if (self.isOutsideSafetyMarginHeader()) self.solid = true
        else self.solid = false

        if (self.headerDetached()) self.hasTop = true
        else self.hasTop = false
      }

      self.previousScrollPositionY = currentScrollPositionY
    }, 16),

    scrollPosition() {
      let scrollPositionY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const scrollMinY = 0
      const scrollMaxY =
        window.scrollMaxY || document.getElementById('__layout').scrollHeight - document.documentElement.clientHeight

      if (scrollPositionY < scrollMinY) scrollPositionY = scrollMinY
      if (scrollPositionY > scrollMaxY) scrollPositionY = scrollMaxY

      return {
        y: scrollPositionY,
      }
    },

    headerDetached() {
      const { y: currentScrollPositionY } = this.scrollPosition()

      return Boolean(currentScrollPositionY > 100)
    },

    isScrollDown() {
      const { y: currentScrollPositionY } = this.scrollPosition()
      const isScrollDown = this.previousScrollPositionY < currentScrollPositionY

      return isScrollDown
    },

    isScrollUp() {
      return !this.isScrollDown()
    },

    isOutsideSafetyMarginHeader() {
      const { height: headerHeight } = this.header.getBoundingClientRect()
      const { top: pageOffsetTop } = this.page.getBoundingClientRect()
      const headerSafetyMargin = headerHeight / 2

      return headerSafetyMargin < -pageOffsetTop
    },
  },
}
