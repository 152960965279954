export const setMeta = payload => {
  const list = []

  for (const [key, value] of Object.entries(payload)) {
    let newMeta = {
      hid: key,
      name: key,
      content: value,
    }

    if (key === 'image') {
      newMeta.itemprop = key
    }

    list.push(newMeta)
  }

  return list
}
