import { logError } from '~/utils/log'

const LinkValidator = (prop, name) => {
  const hasHrefProperty = prop.hasOwnProperty('href')

  if (!hasHrefProperty) {
    logError(`The ${name} object is not valid because it doesn't have the "href" property.`, prop)

    return false
  }

  const hasLabelProperty = prop.hasOwnProperty('label')

  if (!hasLabelProperty) {
    logError(`The ${name} object is not valid because it doesn't have the "label" property.`, prop)

    return false
  }

  return true
}

export default LinkValidator
