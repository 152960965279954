import viewport from '~/mixins/viewport'
import signupButton from '~/components/Molecules/OrganicSquad/SignupButton/SignupButton.vue'
import hotLink from '~/components/Atoms/Link/Link.vue'

export default {
  mixins: [viewport],

  components: {
    signupButton,
    hotLink,
  },

  props: {
    content: Object,
    layout: Object,

    name: {
      type: String,
      required: true,
    },

    isCentered: {
      type: Boolean,
      required: true,
    },

    links: {
      type: Object,
      required: true,
    },

    image: Object,

    labels: {
      type: Object,
      required: true,
    },

    modal: Object,

    isPopup: Boolean,
  },

  methods: {
    openModal(e, data) {
      e.preventDefault()
      const modalOptions = {
        component: Typeform,
        data: this.modal,
        locale: this.$i18n.locale,
      }

      this.$modal.open(modalOptions)
    },
  },

  computed: {
    hasImage() {
      return Boolean(this.image)
    },

    isHomeOrganic() {
      return Boolean(['pt-br'].includes(this.$i18n.locale))
    },

    centered() {
      const prefix = 'top-striper'

      return isCentered ? `${prefix}--centered` : `${prefix}--not-centered`
    },

    link() {
      return this.isMobile ? this.links.mobile : this.links.desktop
    },

    selectedComponent() {
      if (this.isHomeOrganic) {
        return 'signupButton'
      }

      return 'hotLink'
    },

    selectedProps() {
      if (this.selectedComponent === 'hotLink') {
        return this.link
      }

      return { type: 'typeform', link: this.link }
    },
  },
}
