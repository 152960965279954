export const facebook = `<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg>`

export const x = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="m26.37 26-8.795-12.822.015.012L25.52 4h-2.65l-6.46 7.48L11.28 4H4.33l8.211 11.971-.001-.001L3.88 26h2.65l7.182-8.322L19.42 26h6.95zM10.23 6l12.34 18h-2.1L8.12 6h2.11z"/></svg>`

export const linkedIn = `<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg>`

export const youTube = `<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" /></svg>`

export const instagram = `<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>`

export const android = `<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55"></path></svg>`

export const apple = `<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path></svg>`

export const glassdoor = `<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg">
<path d="M9.59671 0C10.9136 0 12 1.08227 12 2.43095H2.40329V12.3879C2.40329 12.4378 2.36214 12.4794 2.31276 12.4794H0.090535C0.0411523 12.4794 0 12.4378 0 12.3879V2.43095C0 1.09892 1.06996 0 2.40329 0H9.59671ZM9.59671 14.569H0C0 15.9011 1.06996 17 2.40329 17H9.59671C10.9136 17 12 15.9177 12 14.569V4.59549C12 4.54554 11.9588 4.50392 11.9095 4.50392H9.68724C9.63786 4.50392 9.59671 4.54554 9.59671 4.59549V14.569Z" fill="currentColor"/></svg>`

export const pinterest = `<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="pinterest" class="svg-inline--fa fa-pinterest fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"></path></svg>`

export const tiktok = `<svg width="200" height="212" viewBox="0 0 200 212" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M195.021 49.4924C181.865 49.4924 169.249 44.648 159.946 36.0249C150.644 27.4018 145.418 15.7063 145.418 3.5114C145.418 3.33433 145.418 3.15725 145.418 2.98018V0H106.376V144.594C106.372 151.12 104.16 157.48 100.051 162.78C95.9424 168.079 90.144 172.05 83.4723 174.134C76.8007 176.218 69.592 176.309 62.8614 174.396C56.1307 172.482 50.2171 168.659 45.9534 163.466C41.6896 158.273 39.2904 151.971 39.0936 145.447C38.8969 138.924 40.9123 132.507 44.8564 127.101C48.8005 121.696 54.4745 117.573 61.0793 115.313C67.6841 113.054 74.887 112.772 81.6738 114.507V77.7004C78.6996 77.3603 75.7056 77.19 72.7089 77.1902C32.5632 77.1902 0 107.367 0 144.594C0 181.82 32.5535 212 72.7089 212C75.7056 212 78.6996 211.83 81.6738 211.49C99.2715 209.466 115.464 201.549 127.204 189.229C138.944 176.91 145.422 161.038 145.418 144.6V66.8242C151.778 74.2917 160.244 79.9823 169.877 83.2663C179.51 86.5503 189.936 87.2997 200 85.4315V49.2643C198.365 49.4144 196.701 49.4924 195.021 49.4924Z" fill="currentColor"/>
</svg>`

export const hotmart = `<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.7092 32.2396C10.9268 32.2092 7.88617 29.1791 7.91723 25.4715C7.94829 21.7639 11.0398 18.7831 14.8218 18.8138C18.6043 18.8443 21.6449 21.8746 21.6138 25.5819C21.5827 29.2896 18.4913 32.2704 14.7092 32.2396ZM29.5279 25.5162C29.7136 21.9511 28.4669 16.1154 25.4144 11.3454C25.3334 11.2217 25.1956 11.2706 25.225 11.4015C25.4286 12.1286 25.4775 13.4423 24.2982 13.3646C22.2089 13.2273 24.3712 8.84399 19.7826 5.80326C19.6907 5.74237 19.581 5.82367 19.6312 5.92084C19.9458 6.50709 20.1328 8.33515 19.4059 8.95833C18.8227 9.4581 17.7521 9.32368 16.7228 7.67732C15.0072 4.93393 15.6585 2.0004 16.8242 0.168125C16.9118 0.0311167 16.7919 -0.0232978 16.6921 0.00909177C10.3796 2.05125 9.11933 9.34344 7.77614 12.0033C7.55079 12.449 7.3555 12.6822 6.97022 12.6569C5.82067 12.5814 6.64872 10.1771 7.04688 9.3402C7.10273 9.2223 6.96461 9.1572 6.89026 9.23332C3.6405 12.5626 0.698704 18.2308 0.13962 23.498C0.157463 23.3733 0.0874126 23.9333 0.0533786 24.3434C0.0401615 24.504 0.0292573 24.666 0.0203358 24.8273C0.0110838 25.02 0.00216227 25.213 0.00051013 25.4077C-0.0665667 33.4008 6.48978 39.9338 14.6441 39.9995C22.7984 40.0653 29.4631 33.6392 29.5305 25.6461C29.5308 25.6024 29.5279 25.5596 29.5279 25.5162Z" fill="#EF4E23"/>
</svg>
`

export default {
  facebook,
  x,
  linkedIn,
  youTube,
  instagram,
  android,
  apple,
  glassdoor,
  pinterest,
  tiktok,
  hotmart,
}
