export const leanObjectFromKeyArray = payload => {
  const { targetObject, keyArray } = payload

  if (!keyArray) return targetObject
  if (!targetObject) return {}

  const newObject = Object.keys(targetObject)
    .filter(key => keyArray.includes(key))
    .reduce((obj, key) => {
      obj[key] = targetObject[key]

      return obj
    }, {})

  return newObject
}
