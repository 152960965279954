var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.selectedComponent,_vm._b({tag:"component",class:[`top-stripe top-stripe--${_vm.name}`, `top-stripe--${_vm.layout.backgroundColor}`],attrs:{"id":"top-stripe"}},'component',_vm.selectedProps,false),[_c('div',{class:['top-stripe__content', { 'top-stripe__content--centered': _vm.isCentered }]},[_c('div',{staticClass:"top-stripe__header"},[(_vm.hasImage)?_c('hot-image',_vm._b({attrs:{"transparent":"","withoutLazyload":true}},'hot-image',_vm.image,false)):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"top-stripe__description"},[_c('p',{class:[
          'top-stripe__text',
          'top-stripe__text--desktop',
          `top-stripe__text--${_vm.layout.textColor}`,
          `top-stripe__text--strong--${_vm.layout.textStrongColor}`,
        ],domProps:{"innerHTML":_vm._s(_vm.content.desktop)}}),_vm._v(" "),_c('p',{class:[
          'top-stripe__text',
          'top-stripe__text--mobile',
          `top-stripe__text--${_vm.layout.textColor}`,
          `top-stripe__text--strong--${_vm.layout.textStrongColor}`,
        ],domProps:{"innerHTML":_vm._s(_vm.content.mobile)}})]),_vm._v(" "),_c('div',{class:['top-stripe__ctas', `top-stripe__ctas--${_vm.layout.ctaBackground}`]},[_c('span',{class:['top-stripe__button', 'top-stripe__button--mobile', `top-stripe__button--${_vm.layout.ctaTextColor}`]},[_vm._v("\n        "+_vm._s(_vm.labels.mobile))]),_vm._v(" "),_c('span',{class:['top-stripe__button', 'top-stripe__button--desktop', `top-stripe__button--${_vm.layout.ctaTextColor}`]},[_vm._v("\n        "+_vm._s(_vm.labels.desktop))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }