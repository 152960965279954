import { render, staticRenderFns } from "./HeaderSidebar.vue?vue&type=template&id=01cc535b&scoped=true"
import script from "./HeaderSidebar.js?vue&type=script&lang=js&external"
export * from "./HeaderSidebar.js?vue&type=script&lang=js&external"
import style0 from "./HeaderSidebar.scss?vue&type=style&index=0&id=01cc535b&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01cc535b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotLink: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Link/Link.vue').default,HotButton: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Button/Button.vue').default})
