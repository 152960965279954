import header from '~/mixins/header.js'
import modalSignup from '~/mixins/modalSignup.js'
import { getDeviceSize } from '~/utils/device'
import debounce from 'lodash.debounce'

export default {
  mixins: [header, modalSignup],

  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    label: {
      type: String,
      required: false,
      default: 'Menu',
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resize)
      window.dispatchEvent(new Event('resize'))
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },

  computed: {
    isNewHomeTestAB() {
      return Boolean(['pt-br'].includes(this.$i18n.locale))
    },

    isNewHomeLATAM() {
      const test = this.$route.query['v']

      return Boolean(['es'].includes(this.$i18n.locale) && test === 't')
    },
  },

  methods: {
    isAnchor(href) {
      return /^(https?:\/\/|\/\/)/.test(href) && /#[\w-]+/.test(href)
    },

    resize(e) {
      this.resizeDebounce(e, this)
    },

    resizeDebounce: debounce((e, self) => {
      const deviceSize = getDeviceSize()
      const isDesktop = /desktop(-m|-l|-xl)/i.test(deviceSize)
      if (isDesktop) self.closeSidebar(e)
    }, 100),

    closeSideBarAndNavigateTo(value) {
      this.closeSidebar(new Event('click'))
    },
  },
}
