import dictionary from './modules/dictionary'
import subdivideUrl from '../../../utils/subdivideUrl'

const { BASE_URL } = process.env

const getItemList = payload => {
  const { routePath, dinamicValue } = payload

  const itemList = []
  const pathsArray = subdivideUrl.subdivideRelativeUrl(routePath)
  const locale = pathsArray[0]

  const homepage = {
    url: `${BASE_URL}/${locale}`,
    content: 'Home',
  }

  itemList.push(homepage)

  pathsArray.shift()
  dinamicValue && pathsArray.splice(-1)

  pathsArray.map(item => {
    const newObj = {
      url: `${BASE_URL}${routePath.split(item)[0]}${item}`,
      content: dictionary[`${item}`] ? dictionary[`${item}`] : item,
    }

    itemList.push(newObj)

    return true
  })

  if (dinamicValue) {
    const lastItem = {
      url: `${BASE_URL}/${routePath}`,
      content: dinamicValue,
    }

    itemList.push(lastItem)
  }

  return itemList
}

export default {
  getItemList,
}
