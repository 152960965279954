import Vue from 'vue'
import ModalWrapper from '~/components/Molecules/Modal/ModalWrapper.vue'

const modal = (ModalWrapper.install = (Vue, options) => {
  Vue.prototype.$modal = new (Vue.extend(ModalWrapper))({ propsData: options })

  Vue.modal = Vue.prototype.$modal
})

export default ctx => {
  if (ctx.isHMR) return

  Vue.use(modal)
}
