// MODULE
import { composeSchema } from '../../schema'
import { getCookieNoticeScript } from './cookieNoticeScript'

// SETTERS
import { setSchema } from '../../../setters'

export const getSharedScript = payload => {
  return [{ ...setSchema({ json: { ...composeSchema({ ...payload }) } }) }]
}
