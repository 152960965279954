export const logError = (message, code = null) => {
  const header = '[Vue warn]: 🔥'

  console.error(header, message)

  if (code) console.error(header, code)
}

export default {
  logError,
}
