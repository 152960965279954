import navigate from '~/mixins/navigate'

export default {
  mixins: [navigate],

  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {
      variable: '',
    }
  },

  props: {
    type: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'secondary', 'tertiary', 'default'].includes(prop),
    },

    size: {
      type: String,
      default: 'medium',
      validator: prop => ['medium', 'large'].includes(prop),
    },

    keepParameters: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.$route) {
      let parameters = this.$route.fullPath
      parameters.indexOf('?') != -1 ? (this.variable = `?${this.$route.fullPath.split('?')[1]}`) : (this.variable = '')
    }
  },

  computed: {
    typeClasses() {
      return `button--${this.type}`
    },
    sizeClasses() {
      return `button--${this.size}`
    },
  },
}
