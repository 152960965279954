import footer from './footer.json'
import header from './header.json'
import headerSidebar from './headerSidebar.json'
import languageSwitch from './languageSwitch.json'
import madeWithLove from './madeWithLove.json'
import socialIcons from './socialIcons.json'
import breadcrumb from './breadcrumb.json'

export default {
  footer,
  header,
  headerSidebar,
  languageSwitch,
  madeWithLove,
  socialIcons,
  breadcrumb,
}
