// SERVICE
import { useRouteService } from '../../../../../routes'

// MODULES
import { setDefaultAlternate } from './modules/defaults'
import { setVariantsAlternate } from './modules/variants'

export const getAlternateLinks = payload => {
  const { routeName } = payload

  const pages = useRouteService({
    item: 'getPages',
    filters: { routeName: [routeName] },
    onlyAvailableLocales: true,
  })

  if (Object.keys(pages).length === 0) return []

  const pageName = useRouteService({ item: 'toggleNameFormat', name: routeName })

  const page = { ...pages[pageName] }

  const variantsAlternate = [...setVariantsAlternate({ ...payload, page })]

  const defaultAlternate = { ...setDefaultAlternate({ page }) }

  return [...variantsAlternate, { ...defaultAlternate }]
}
