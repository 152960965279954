import { render, staticRenderFns } from "./Image.vue?vue&type=template&id=61c11c75&scoped=true"
import script from "./Image.js?vue&type=script&lang=js&external"
export * from "./Image.js?vue&type=script&lang=js&external"
import style0 from "./Image.scss?vue&type=style&index=0&id=61c11c75&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c11c75",
  null
  
)

export default component.exports