const hostName = process.env.BASE_URL ? process.env.BASE_URL : ''

const checkIfAbsolutePath = path => {
  const isAbsolutePath = path.includes('http')

  return !!isAbsolutePath
}

const checkIfStartWithSlash = path => {
  const hasSlash = path[0] === '/'

  return hasSlash
}

const transformAbsoluteToRelativePath = path => {
  const partials = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(path)

  if (!partials || partials.length < 3) {
    console.error('Path does not have enough parts:', path)

    return path
  }

  return partials[2]
}

export const parseLink = path => {
  let verifiedPath = path

  verifiedPath = checkIfAbsolutePath(verifiedPath) ? transformAbsoluteToRelativePath(verifiedPath) : verifiedPath

  verifiedPath = checkIfStartWithSlash(verifiedPath) ? verifiedPath : `${verifiedPath}`

  const newUrl = `${hostName}${verifiedPath}`

  return newUrl
}
