// DOCS: https://schema.org/BreadcrumbList
export const setSchemaBreadcrumb = payload => {
  const { breadcrumbs } = payload

  if (breadcrumbs.length === 0) return

  const itemListElement = []

  breadcrumbs.map((item, index) => {
    const newItemElement = {
      '@type': 'ListItem',
      position: index,
      item: {
        '@id': item.url,
        name: item.content,
      },
    }

    itemListElement.push(newItemElement)
  })

  const baseSchema = {
    '@type': 'BreadcrumbList',
    itemListElement,
  }

  return baseSchema
}
