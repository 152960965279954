export default {
  data() {
    return {
      isOpen: false,
    }
  },
  props: {
    label: {
      type: String,
    },
    links: {
      type: Array,
    },
  },

  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    },

    close() {
      this.isOpen = false
    },

    click() {
      if (this.isOpen) this.close()
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('click', this.click)
    })
  },

  beforeDestroy() {
    window.removeEventListener('click', this.click)
  },
}
