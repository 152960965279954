// Project configs
import pages from '../../../../config/i18n.pages'

// Utilities
import { leanObjectFromKeyArray } from '~/utils/leanObjectFromKeyArray'
import { toggleNameFormat } from './toggleNameFormat'

/*
 ** !!OBS!!:
 ** routerName is a value from vue router,
 ** pageName is a value from i18n.pages
 */

const getOnlyAvailableRoutes = payload => {
  const { pages } = payload

  if (!pages) return {}

  const newObject = {}

  for (const [key, value] of Object.entries(pages)) {
    newObject[key] = {}

    for (const [locale, route] of Object.entries(value)) {
      if (route) newObject[key][locale] = route
    }
  }

  return newObject
}

const getFilteredPages = payload => {
  const { filters } = payload
  const { routeName, pageName } = filters

  let result, keyArray

  result = { ...pages }
  keyArray = []

  if (routeName) keyArray = [...routeName.map(name => toggleNameFormat({ name }))]
  if (pageName) keyArray = [...pageName]

  result = {
    ...leanObjectFromKeyArray({ targetObject: result, keyArray }),
  }

  return result
}

export const getPages = payload => {
  const { filters, onlyAvailableLocales = false } = payload

  let result
  result = filters ? { ...getFilteredPages({ ...payload }) } : { ...pages }

  if (onlyAvailableLocales) {
    result = { ...getOnlyAvailableRoutes({ pages: { ...result } }) }
  }

  return result
}
