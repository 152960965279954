import { logError } from '~/utils/log'

export const I18nValidator = (prop, name) => {
  const hasFormatProperty = prop.hasOwnProperty('format')

  if (!hasFormatProperty) {
    logError(`The i18n object for ${name} is not valid because it doesn't have the "format" property.`, prop)

    return false
  }

  const hasArgsProperty = prop.hasOwnProperty('args')

  if (!hasArgsProperty) {
    logError(`The i18n object for ${name} is not valid because it doesn't have the "args" property.`, prop)

    return false
  }

  const hasPathProperty = prop.hasOwnProperty('path')

  if (!hasPathProperty) {
    logError(`The i18n object for ${name} is not valid because it doesn't have the "path" property.`, prop)

    return false
  }

  return true
}

export default I18nValidator
