import { getSharedLink } from './modules/shared'
import { getAlternateLinks } from './modules/alternate'
import { setCanonical } from './modules/canonical'

const getLinks = payload => {
  const { link = [] } = payload

  const shared = [...getSharedLink({ ...payload })]
  const alternateLinks = [...getAlternateLinks({ ...payload })]
  const canonical = { ...setCanonical({ ...payload }) }

  return [...shared, ...alternateLinks, canonical, ...link]
}

export { getLinks }
