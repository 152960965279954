export const getDeviceSize = () => {
  if (process.client) {
    return window
      .getComputedStyle(document.querySelector('body'), '::before')
      .getPropertyValue('content')
      .replace(/"/g, '')
      .replace(/'/g, '')
  }

  return ''
}

export const getDeviceType = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return 'mobile'
  }

  return 'desktop'
}
