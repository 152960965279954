export default {
  data() {
    return {
      pages: [
        'index___pt-br',
        'hotmart-app___pt-br',
        'solutions___pt-br',
        'payments___pt-br',
        'affiliates___pt-br',
        'about___pt-br',
        'members-area___pt-br',
      ],
    }
  },

  computed: {
    isStripePage() {
      return Boolean(this.$route && this.pages.includes(this.$route.name))
    },

    topStripeClass() {
      return Boolean(this.$route && this.pages.includes(this.$route.name)) ? 'with--topStripe' : ''
    },
  },
}
