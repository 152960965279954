// MODULES
import { setGroupLink, setFont } from '../../../setters'

const baseIconsPath = '/static/app-hotmart/images/icons'
const baseImagePath = '/static/app-hotmart/images/splashs/launch-'

const setMedia = payload => {
  const { width, height, ratio } = payload

  return `(device-width: ${width}px) and (device-height: ${height}px) and (-webkit-device-pixel-ratio: ${ratio}) and (orientation: portrait)`
}

export const getSharedLink = payload => [
  // Set a link set, grouped by 'sharedAttributes'
  ...setGroupLink({
    sharedAttributes: { rel: 'icon', type: 'image/png' },
    dinamicAttributes: { key: 'sizes', value: 'href' },
    group: {
      '32x32': `${baseIconsPath}/favicon-32x32.png`,
      '16x16': `${baseIconsPath}/favicon-16x16.png`,
    },
  }),

  ...setGroupLink({
    sharedAttributes: { rel: 'apple-touch-icon' },
    dinamicAttributes: { key: 'sizes', value: 'href' },
    group: {
      '180x180': `${baseIconsPath}/apple-touch-icon.png`,
      '512x512': `${baseIconsPath}/android-chrome-512x512.png`,
    },
  }),

  ...setGroupLink({
    sharedAttributes: { rel: 'apple-touch-startup-image' },
    dinamicAttributes: { key: 'media', value: 'href' },
    group: {
      [`${setMedia({ width: 320, height: 569, ratio: 2 })}`]: `${baseImagePath}640x1136.png`,
      [`${setMedia({ width: 375, height: 667, ratio: 2 })}`]: `${baseImagePath}750x1294.png`,
      [`${setMedia({ width: 414, height: 736, ratio: 3 })}`]: `${baseImagePath}1242x2148.png`,
      [`${setMedia({ width: 375, height: 812, ratio: 3 })}`]: `${baseImagePath}1125x2436.png`,
      [`${setMedia({ width: 768, height: 1024, ratio: 2 })}`]: `${baseImagePath}1536x2048.png`,
      [`${setMedia({ width: 834, height: 834, ratio: 2 })}`]: `${baseImagePath}1668x2224.png`,
      [`${setMedia({ width: 1024, height: 1024, ratio: 2 })}`]: `${baseImagePath}2048x2732.png`,
    },
  }),

  // Set others links
  {
    rel: 'mask-icon',
    color: '#f04e23',
    href: `${baseIconsPath}/safari-pinned-tab.svg`,
  },
  {
    rel: 'shortcut icon',
    href: `${baseIconsPath}/favicon.ico`,
  },
  {
    rel: 'shortcut icon',
    href: `${baseIconsPath}/android-chrome-192x192.png`,
  },
]
