// CONFIG
import i18nLocales from '~/config/i18n.locales'

// SERVICE
import { useRouteService } from '../../../routes'

const getPage = payload => {
  const { routeName } = payload

  const pages = useRouteService({
    item: 'getPages',
    filters: { routeName: [routeName] },
    onlyAvailableLocales: true,
  })

  const pageName = useRouteService({ item: 'toggleNameFormat', name: routeName })

  return pages[pageName]
}

const getAvailableLocales = payload => {
  const { page } = payload

  return Object.keys(page)
}

export const setAlternate = payload => {
  const { callback, locale } = payload

  const page = { ...getPage({ ...payload }) }

  const locales = [...getAvailableLocales({ ...payload, page })]

  const result = []

  locales.map(loc => {
    const { iso, code } = i18nLocales.find(locale => locale.code === loc)

    result.push({ ...callback({ code, iso }) })
  })

  return result
}
