// MODULES
import { getSharedScript } from './modules/shared'

const getScripts = payload => {
  const { script = [] } = payload

  const shared = getSharedScript({ ...payload })

  return [...shared, ...script]
}

export { getScripts }
