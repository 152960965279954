import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('modalSignup', ['setSignupModalOpen']),

    openModal() {
      document.body.style.overflow = 'hidden'
      this.setSignupModalOpen(true)
    },
  },
}
