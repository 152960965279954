export default {
  data: [
    {
      id: 'c0f362521c1d',
      tag: 'Tableau principal',
      locale: 'fr',
      icon: {
        fontAwesome: ['far', 'tasks'],
      },
      title: 'Comment accéder aux informations principales',
      body: {
        short: `<p>Vos principales informations sont exhibées juste après le login. Sur le tableau principal, il est possible de voir le total de vos revenus, le taux de remboursement, en plus d’un graphique disposant de divers filtres pour que vous puissiez accompagner vos ventes d’une manière simple et rapide.</p>`,
      },
      seo: {
        title: 'Comment accéder aux informations principales - Tour sur la plateforme - Hotmart',
        description:
          'Vos principales informations sont exhibées juste après le login. Sur le tableau principal, il est possible de voir le total de vos revenus, le taux de remboursement, en plus d’un graphique disposant de divers filtres pour que vous puissiez accompagner vos ventes d’une manière simple et rapide.',
      },
      video: {
        id: 'lXgOdHrXoZE',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/informations_principales.jpg',
        },
      },
    },
    {
      id: 'dcae650db3de',
      tag: 'Marché',
      locale: 'fr',
      icon: {
        fontAwesome: ['fal', 'th'],
      },
      title: 'Découvrez les volets du marché',
      body: {
        short: `<p>Les produits remplissant tous les critères de la plateforme sont exhibés sur le Marché Hotmart. Si votre objectif est de travailler comme Affilié(e), c’est ici que vous allez trouver les meilleurs produits pour votre stratégie de ventes.</p>`,
      },
      seo: {
        title: 'Découvrez les volets du marché - Tour sur la plateforme - Hotmart',
        description:
          'Les produits remplissant tous les critères de la plateforme sont exhibés sur le Marché Hotmart. Si votre objectif est de travailler comme Affilié(e), c’est ici que vous allez trouver les meilleurs produits pour votre stratégie de ventes.',
      },
      video: {
        id: 'tBSmwck1CCE',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/volets_du_marche.jpg',
        },
      },
    },
    {
      id: 'e76f98a39b5f',
      tag: 'Mes Affiliés',
      locale: 'fr',
      icon: {
        name: 'affiliate',
      },
      title: 'Gérez vos Affiliés',
      body: {
        short: `<p>La Plateforme Hotmart comporte un espace pour les Producteurs consacré exclusivement à la gestion des Affiliés de leurs produits. Dans le menu « Mes Affiliés », il est possible d’avoir des informations spécifiques et même d’envoyer des messages à chacun d’entre eux.</p>`,
      },
      seo: {
        title: 'Gérez vos Affiliés - Tour sur la plateforme - Hotmart',
        description:
          'La Plateforme Hotmart comporte un espace pour les Producteurs consacré exclusivement à la gestion des Affiliés de leurs produits. Dans le menu « Mes Affiliés », il est possible d’avoir des informations spécifiques et même d’envoyer des messages à chacun d’entre eux.',
      },
      video: {
        id: 'LvZsj-G-Qfk',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/gerez_vos_affilies.jpg',
        },
      },
    },
    {
      id: '0f844d85c495',
      tag: 'Produits',
      locale: 'fr',
      icon: {
        fontAwesome: ['fal', 'cube'],
      },
      title: 'Administrez votre Produit',
      body: {
        short: `<p>En quelques étapes vous pouvez inscrire votre produit sur la plateforme Hotmart pour commencer vos ventes. Ici, la première étape consiste à remplir toutes les informations nécessaires pour qu’il soit envoyé à l’évaluation et mis à disposition sur le marché.</p>`,
        full: `<p><b>Dans cette vidéo vous allez aussi découvrir :</b></p>
        <p>Comment définir le prix de votre produit</p>
        <p>Comment gérer votre produit</p>
        <p>L’espace membre</p>
        <p>Comment créer des modules</p>
        <p>Le Player vidéo Hotmart</p>
        <p>Les Annonces</p>
        <p>Comment gérer les classes</p>
        <p>La section utilisateurs</p>
        <p>Les annonces et commentaires durant les cours</p>
        <p>Comment accompagner les ventes</p>
        <p>Le programme d'affiliation</p>`,
      },
      seo: {
        title: 'Administrez votre Produit - Tour sur la plateforme - Hotmart',
        description:
          'En quelques étapes vous pouvez inscrire votre produit sur la plateforme Hotmart pour commencer vos ventes. Ici, la première étape consiste à remplir toutes les informations nécessaires pour qu’il soit envoyé à l’évaluation et mis à disposition sur le marché.',
      },
      video: {
        id: '92iLOFbrFD8',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/administrez_votre_produit.jpg',
        },
      },
    },
    {
      id: 'ff0b24b5b451',
      tag: 'Ventes',
      locale: 'fr',
      icon: {
        fontAwesome: ['far', 'shopping-cart'],
      },
      title: 'Accompagnez vos ventes',
      body: {
        short: `<p>Accompagner les résultats de vos ventes sur la plateforme Hotmart est très simple. Si vous voulez connaitre l’évolution de vos chiffres, c’est l’endroit idéal. Dans cet espace, vous pourrez avoir une vue générale des performances de vos produits et aussi générer des rapports personnalisés avec les informations pertinentes pour votre business</p>`,
      },
      seo: {
        title: 'Accompagnez vos ventes - Tour sur la plateforme - Hotmart',
        description:
          'Accompagner les résultats de vos ventes sur la plateforme Hotmart est très simple. Si vous voulez connaitre l’évolution de vos chiffres, c’est l’endroit idéal. Dans cet espace, vous pourrez avoir une vue générale des performances de vos produits et aussi générer des rapports personnalisés avec les informations pertinentes pour votre business',
      },
      video: {
        id: '---eM37BRU8',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/accompagnez_vos_ventes.jpg',
        },
      },
    },
    {
      id: 'de66605432e4',
      tag: 'Outils',
      locale: 'fr',
      icon: {
        fontAwesome: ['far', 'cog'],
      },
      title: 'Découvrez les outils de l’univers Hotmart',
      body: {
        short: `<p>Découvrez les solutions créées pour vous aider à gérer votre business numérique sans avoir besoin de sortir de la plateforme Hotmart. </p>`,
        full: `<p><b>Dans cette vidéo vous découvrirez également :</b></p>
        <p>La page de paiements avec le <b>Checkout Builder</b></p>
        <p>L’entonnoir de ventes</p>
        <p>La gestion automatique de listes d’e-mails avec le <b>Listboss</b></p>`,
      },
      seo: {
        title: 'Découvrez les outils de l’univers Hotmart - Tour sur la plateforme - Hotmart',
        description:
          'Découvrez les solutions créées pour vous aider à gérer votre business numérique sans avoir besoin de sortir de la plateforme Hotmart. ',
      },
      video: {
        id: 'qTYB_3gVZI4',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/decouvrez_les_outils.jpg',
        },
      },
    },
    {
      id: 'd86679fe24d0',
      tag: 'Analytics',
      locale: 'fr',
      icon: {
        name: 'analytics',
      },
      title: 'Accompagnez vos résultat',
      body: {
        short: `<p>Des graphiques et des rapports personnalisés pour pouvoir accompagner toute la stratégie de votre business numérique sans sortir de la plateforme Hotmart. Avec Analytics, vous pouvez accompagner rapidement toutes les données sur vos ventes et les performances de vos campagnes.</p>`,
        full: `<p><b>Dans cette vidéo vous découvrirez également :</b></p>
        <p>Les segmentations d’utilisateur pour définir vos stratégies de campagne</p>
        <p>Comment accompagner les conversions de vos campagnes</p>`,
      },
      seo: {
        title: 'Accompagnez vos résultat - Tour sur la plateforme - Hotmart',
        description:
          'Des graphiques et des rapports personnalisés pour pouvoir accompagner toute la stratégie de votre business numérique sans sortir de la plateforme Hotmart. Avec Analytics, vous pouvez accompagner rapidement toutes les données sur vos ventes et les performances de vos campagnes.',
      },
      video: {
        id: 'X0CJpQ9VBD0',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/accompagnez_vos_resultat.jpg',
        },
      },
    },
  ],
}
