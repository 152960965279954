import { logError } from '~/utils/log'

const TitleValidator = (prop, name) => {
  const isTitleTypeString = typeof prop === 'string' ? true : false

  if (!isTitleTypeString) {
    const hasLabelProperty = prop.hasOwnProperty('label')

    if (!hasLabelProperty) {
      logError(`The title object for ${name} is not valid because it doesn't have the "label" property.`, prop)

      return false
    }

    const hasAlignProperty = prop.hasOwnProperty('align')

    if (hasAlignProperty) {
      const hasAvailableAlignOptions = ['left', 'center', 'right'].includes(prop.align)

      if (!hasAvailableAlignOptions) {
        logError(`The title object for ${name} is not valid because it doesn't have the available align options.`, prop)

        return false
      }
    }
  }

  return true
}

export default TitleValidator
