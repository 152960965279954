import { logError } from '~/utils/log'

const IconValidator = (prop, name) => {
  const hasFontAwesomeIcon = prop.hasOwnProperty('fontAwesome')
  const hasHotmartIcon = prop.hasOwnProperty('name')

  const hasIcon = hasHotmartIcon || hasFontAwesomeIcon

  if (!hasIcon) {
    logError(`The prop "${name}" needs to receive an icon object.`, prop)

    return false
  }

  if (hasFontAwesomeIcon) {
    const isFontAwesomeTypeArray = Array.isArray(prop.fontAwesome) ? true : false

    if (!isFontAwesomeTypeArray) {
      logError(`The ${name} object is not valid because the fontAwesome property needs to be an array.`, prop)

      return false
    }
  }

  return true
}

export default IconValidator
