import { GrowthBook } from '@growthbook/growthbook'
import { growthBookConfig, syncAttributes } from '~/plugins/growthbook.constants'

export default (context, inject) => {
  const { app, store, $config, $growthbook } = context

  if (process.client) {
    const gb = new GrowthBook({
      ...growthBookConfig($config),
      trackingCallback: (experiment, result) => {
        try {
          app.$gtm.push({
            event: 'experiment_viewed',
            experimentId: experiment.key,
            variationId: result.key,
            experiment_id: experiment.key,
            variation_id: result.key,
          })
        } catch (error) {
          console.error('Error pushing data to GTM:', error)
        }
      },
    })

    syncAttributes(context, gb)

    gb.initSync({
      payload: store.getters['growthbook/growthbookConfig'],
      streaming: false,
    })

    inject('growthbook', gb)
  }
}
