export default {
  data: [
    {
      id: 'f2bb98f10a5d',
      locale: 'pt-br',
      tag: 'Painel Principal',
      icon: {
        fontAwesome: ['far', 'tasks'],
      },
      title: 'Como acessar as informações principais',
      body: {
        short: `<p>Suas informações principais são exibidas logo após o login. No Painel Principal é possível ver o total da sua receita, a taxa de reembolso, além de um gráfico com diversos filtros para você acompanhar suas vendas de forma simples e rápida.</p>`,
      },
      seo: {
        title: 'Como acessar as informações principais - Tour na Plataforma - Hotmart',
        description:
          'Suas informações principais são exibidas logo após o login. No Painel Principal é possível ver o total da sua receita, a taxa de reembolso, além de um gráfico com diversos filtros para você acompanhar suas vendas de forma simples e rápida.',
      },
      video: {
        id: 'dOoxD4QKf_k',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/informacoes_principais.jpg',
        },
      },
    },
    {
      id: 'b3e717fae7c9',
      locale: 'pt-br',
      tag: 'Mercado',
      icon: {
        fontAwesome: ['fal', 'th'],
      },
      title: 'Conheça as abas do mercado',
      body: {
        short: `<p>Os produtos que cumprem todos os critérios da plataforma, são exibidos no Mercado Hotmart. Se o seu objetivo é trabalhar como Afiliado(a), é aqui onde você vai encontrar os melhores produtos para a sua estratégia de vendas.</p>`,
        full: `<p><b>Neste vídeo você também vai saber sobre:</b></p>
          <p>Tipos de rastreamento de links - Hotlinks</p>
          <p>Materiais para divulgação</p>
          <p>Modelos de Afiliação</p>`,
      },
      seo: {
        title: 'Conheça as abas do mercado - Tour na Plataforma - Hotmart',
        description:
          'Os produtos que cumprem todos os critérios da plataforma, são exibidos no Mercado Hotmart. Se o seu objetivo é trabalhar como Afiliado(a), é aqui onde você vai encontrar os melhores produtos para a sua estratégia de vendas.',
      },
      video: {
        id: 'G3tkUyBIo18',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/abas_mercado.jpg',
        },
      },
    },
    {
      id: '53f33beb4b6a',
      locale: 'pt-br',
      tag: 'Meus Afiliados',
      icon: {
        name: 'affiliate',
      },
      title: 'Gerencie seus Afiliados',
      body: {
        short:
          '<p>A Plataforma Hotmart conta com uma área dedicada exclusivamente para que Produtores possam gerenciar os Afiliados de seus produtos. No Menu “Meus Afiliados” é possível ter informações específicas e até enviar mensagens para cada um deles.</p>',
      },
      seo: {
        title: 'Gerencie seus Afiliados - Tour na Plataforma - Hotmart',
        description:
          'A Plataforma Hotmart conta com uma área dedicada exclusivamente para que Produtores possam gerenciar os Afiliados de seus produtos. No Menu “Meus Afiliados” é possível ter informações específicas e até enviar mensagens para cada um deles.',
      },
      video: {
        id: 'YjhpW__gbVc',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/gerencie_afiliados.jpg',
        },
      },
    },
    {
      id: '9eeb85d8b5a6',
      locale: 'pt-br',
      tag: 'Produtos',
      icon: {
        fontAwesome: ['fal', 'cube'],
      },
      title: 'Administre seu Produto',
      body: {
        short:
          '<p>Em poucos passos você pode cadastrar o seu produto na plataforma Hotmart para começar suas vendas. Aqui, o primeiro passo é preencher todas as informações necessárias para que ele seja enviado para avaliação e seja disponibilizado no mercado.</p>',
        full: `<p><b>Neste vídeo você também vai saber sobre:</b></p>
          <p>Planos de assinatura</p>
          <p>Como gerenciar o seu produto</p>
          <p>Área de membros</p>
          <p>Player de vídeo da Hotmart</p>
          <p>Legendas</p>
          <p>Anúncios</p>
          <p>Seção de usuários</p>
          <p>Certificado de conclusão do curso</p>
          <p>Templates personalizados</p>
          <p>Programa de Afiliados</p>
          <p>Como configurar sua página de pagamentos com o <b>Checkout Builder</b></p>
          <p>Como oferecer produtos relacionados na página de pagamentos com o <b>Order Bump</b></p>
          <p>Como gerenciar automaticamente suas listas de emails com o <b>Listboss</b></p>`,
      },
      seo: {
        title: 'Administre seu Produto - Tour na Plataforma - Hotmart',
        description:
          'Em poucos passos você pode cadastrar o seu produto na plataforma Hotmart para começar suas vendas. Aqui, o primeiro passo é preencher todas as informações necessárias para que ele seja enviado para avaliação e seja disponibilizado no mercado.',
      },
      video: {
        id: 'Tbvxus6BK5k',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/administre_produto.jpg',
        },
      },
    },
    {
      id: '07829e0ed6d9',
      locale: 'pt-br',
      tag: 'Vendas',
      icon: {
        fontAwesome: ['far', 'shopping-cart'],
      },
      title: 'Acompanhe suas vendas',
      body: {
        short: `<p>Acompanhar os resultados das suas vendas na plataforma Hotmart é muito simples. Se você quer ver como andam seus números, este é o lugar certo. Nessa área você poderá ter uma visão geral do desempenho de seus produtos e também gerar relatórios personalizados com as informações mais relevantes para o seu negócio.</p>`,
        full: `<p><b>Neste vídeo você também vai saber sobre:</b></p>
          <p>Dados dos seus compradores</p>
          <p>Transações</p>
          <p>Reembolso</p>`,
      },
      seo: {
        title: 'Acompanhe suas vendas - Tour na Plataforma - Hotmart',
        description:
          'Acompanhar os resultados das suas vendas na plataforma Hotmart é muito simples. Se você quer ver como andam seus números, este é o lugar certo. Nessa área você poderá ter uma visão geral do desempenho de seus produtos e também gerar relatórios personalizados com as informações mais relevantes para o seu negócio',
      },
      video: {
        id: 'nmxNUZGmq6g',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/acompanhe_vendas.jpg',
        },
      },
    },
    {
      id: 'b0b7529ce7ca',
      locale: 'pt-br',
      tag: 'Saldo',
      icon: {
        fontAwesome: ['far', 'usd-circle'],
      },
      title: 'Veja sua movimentação financeira',
      body: {
        short: `<p>Acompanhar a movimentação financeira do seu negócio digital na plataforma Hotmart é bem simples. No menu Saldo você pode ver o total acumulado na plataforma nas diferentes moedas em que o seu produto foi vendido.</p>`,
        full: `<p><b>Neste vídeo você também vai saber sobre:</b></p>
          <p>Como fazer saques</p>
          <p>Ver o histórico de suas transações</p>`,
      },
      seo: {
        title: 'Veja sua movimentação financeira - Tour na Plataforma - Hotmart',
        description:
          'Acompanhar a movimentação financeira do seu negócio digital na plataforma Hotmart é bem simples. No menu Saldo você pode ver o total acumulado na plataforma nas diferentes moedas em que o seu produto foi vendido.',
      },
      video: {
        id: 'fEUwMAm32m8',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/movimentacao_financeira.jpg',
        },
      },
    },
    {
      id: '26f3f3092aac',
      locale: 'pt-br',
      tag: 'Ferramentas',
      icon: {
        fontAwesome: ['far', 'cog'],
      },
      title: 'Conheça as ferramentas do universo Hotmart',
      body: {
        short: `<p>Conheça as soluções criadas para ajudar você a gerenciar o seu negócio digital sem precisar sair da plataforma Hotmart.</p>`,
        full: `<p><b>Neste vídeo você também vai saber sobre:</b></p>
          <p>Central de colaboradores</p>
          <p>Funil de vendas</p>
          <p>Integração de serviços externos - HotConnect</p>
          <p>Captura de leads - HotLeads</p>
          <p>Pixel de rastreamento</p>
          <p>Pesquisa de opinião com seus compradores - Hotmart Ask</p>
          <p>Gerenciador de Links</p>`,
      },
      seo: {
        title: 'Conheça as ferramentas do universo Hotmart - Tour na Plataforma - Hotmart',
        description:
          'Conheça as soluções criadas para ajudar você a gerenciar o seu negócio digital sem precisar sair da plataforma Hotmart.',
      },
      video: {
        id: 'SCtYKIFSkRg',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/universo_hotmart.jpg',
        },
      },
    },
    {
      id: '74d94a5aa7cb',
      locale: 'pt-br',
      tag: 'Analytics',
      icon: {
        name: 'analytics',
      },
      title: 'Acompanhe seus resultados',
      body: {
        short: `<p>Gráficos e relatórios personalizados para você acompanhar toda a estratégia do seu negócio digital sem sair da plataforma Hotmart. Com o Analytics você pode acompanhar de forma rápida todos os dados sobre suas vendas e desempenho de suas campanhas.</p>`,
      },
      seo: {
        title: 'Acompanhe seus resultados - Tour na Plataforma - Hotmart',
        description:
          'Gráficos e relatórios personalizados para você acompanhar toda a estratégia do seu negócio digital sem sair da plataforma Hotmart. Com o Analytics você pode acompanhar de forma rápida todos os dados sobre suas vendas e desempenho de suas campanhas.',
      },
      video: {
        id: 'grHaeb_1uSo',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/acompanhe_seus_resultados.jpg',
        },
      },
    },
  ],
}
