export default {
  data: [
    {
      id: 'fe7b533dc238',
      locale: 'es-mx',
      tag: 'Panel principal',
      icon: {
        fontAwesome: ['far', 'tasks'],
      },
      title: 'Cómo acceder a las principales informaciones',
      body: {
        short: `<p>Tus principales informaciones son exhibidas justo después de iniciar sesión. En el Panel Principal podrás ver el total de tus ingresos y la tasa de reembolso, además de un gráfico con diversos filtros para que acompañes tus ventas de forma simple y rápida.</p>`,
      },
      seo: {
        title: 'Cómo acceder a las principales informaciones - Tour na Plataforma - Hotmart',
        description:
          'Tus principales informaciones son exhibidas justo después de iniciar sesión. En el Panel Principal podrás ver el total de tus ingresos y la tasa de reembolso, además de un gráfico con diversos filtros para que acompañes tus ventas de forma simple y rápida.',
      },
      video: {
        id: 'G2mxnje7Fz8',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/principales_informaciones.jpg',
        },
      },
    },
    {
      id: '819212e8929f',
      locale: 'es-mx',
      tag: 'Mercado',
      icon: {
        fontAwesome: ['fal', 'th'],
      },
      title: 'Conoce las pestañas del mercado',
      body: {
        short: `<p>Los productos que cumplen todos los criterios de la plataforma son exhibidos en el Mercado Hotmart. Si tu objetivo es trabajar como Afiliado(a), aquí es donde vas a encontrar los mejores productos para tu estrategia de ventas.</p>`,
      },
      seo: {
        title: 'Conoce las pestañas del mercado - Tour na Plataforma - Hotmart',
        description:
          'Los productos que cumplen todos los criterios de la plataforma son exhibidos en el Mercado Hotmart. Si tu objetivo es trabajar como Afiliado(a), aquí es donde vas a encontrar los mejores productos para tu estrategia de ventas.',
      },
      video: {
        id: 'f1RjNj0fkOQ',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/pestanas_del_mercado.jpg',
        },
      },
    },
    {
      id: 'c488f2ab7222',
      locale: 'es-mx',
      tag: 'Mis Afiliados',
      icon: {
        name: 'affiliate',
      },
      title: 'Gestiona a tus Afiliados',
      body: {
        short: `<p>La Plataforma Hotmart cuenta con un área dedicada exclusivamente para que los Productores puedan gestionar a los Afiliados de sus productos. En el Menú “Mis Afiliados” se pueden obtener informaciones específicas sobre ellos e incluso enviarles mensajes.</p>`,
      },
      seo: {
        title: 'Gestiona a tus Afiliados - Tour na Plataforma - Hotmart',
        description:
          'La Plataforma Hotmart cuenta con un área dedicada exclusivamente para que los Productores puedan gestionar a los Afiliados de sus productos. En el Menú “Mis Afiliados” se pueden obtener informaciones específicas sobre ellos e incluso enviarles mensajes.',
      },
      video: {
        id: 'UwZaFSIyF1o',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/gestiona_afiliados.jpg',
        },
      },
    },
    {
      id: '97dc4fed50cd',
      locale: 'es-mx',
      tag: 'Productos',
      icon: {
        fontAwesome: ['fal', 'cube'],
      },
      title: 'Administra tu Producto',
      body: {
        short: `<p>En pocos pasos puedes registrar tu producto en la plataforma Hotmart para empezar a vender. Aquí, el primer paso es rellenar todas las informaciones necesarias para que el producto sea enviado para su evaluación y sea incluido en el mercado.</p>`,
        full: `<p><b>En este video también vas a aprender sobre</b></p>
        <p>Fijación de precios</p>
        <p>Plazo de reembolso</p>
        <p>Opciones de IVA</p>`,
      },
      seo: {
        title: 'Administra tu Producto - Tour na Plataforma - Hotmart',
        description:
          'En pocos pasos puedes registrar tu producto en la plataforma Hotmart para empezar a vender. Aquí, el primer paso es rellenar todas las informaciones necesarias para que el producto sea enviado para su evaluación y sea incluido en el mercado.',
      },
      video: {
        id: '7Owd9VPs_h4',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/administra_tu_producto.jpg',
        },
      },
    },
    {
      id: 'b18e4be945b7',
      locale: 'es-mx',
      tag: 'Hotmart Club',
      icon: {
        fontAwesome: ['fal', 'users'],
      },
      title: 'Área de Miembros',
      body: {
        short: `<p>El área de miembros gratuita de Hotmart tiene herramientas que permiten crear, organizar y distribuir tu curso en un ambiente seguro y estable. Así puedes garantizarles la mejor experiencia a tus alumnos.</p>`,
        full: `<p><b>En este video también vas a aprender sobre:</b></p>
        <p>Cómo gestionar tu contenido</p>
        <p>Formatos permitidos</p>
        <p>Idioma y Formato del producto</p>
        <p>Player de video</p>
        <p>Subtítulos automáticos</p>
        <p>Quiz</p>
        <p>Anuncios para otros productos</p>
        <p>Comunicados para los alumnos</p>
        <p>Comunidades y comentarios</p>`,
      },
      seo: {
        title: 'Área de Miembros - Tour na Plataforma - Hotmart',
        description:
          'El área de miembros gratuita de Hotmart tiene herramientas que permiten crear, organizar y distribuir tu curso en un ambiente seguro y estable. Así puedes garantizarles la mejor experiencia a tus alumnos.',
      },
      video: {
        id: 'GUszq3vKmsw',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/area_de_miembros.jpg',
        },
      },
    },
    {
      id: 'f23f7af39b9c',
      locale: 'es-mx',
      tag: 'Ventas',
      icon: {
        fontAwesome: ['far', 'shopping-cart'],
      },
      title: 'Acompaña tus ventas',
      body: {
        short: `<p>Acompañar los resultados de tus ventas en la plataforma Hotmart es muy simple. Si quieres ver cómo andan tus números, este es el lugar correcto. En esta área podrás tener una visión general del rendimiento de tus productos y también crear informes personalizados con las informaciones más relevantes para tu negocio.</p>`,
        full: `<p><b>En este video también vas a aprender sobre:</b></p>
        <p>Datos de tus compradores</p>
        <p>Transacciones</p>
        <p>Informes sobre tus ventas</p>`,
      },
      seo: {
        title: 'Acompaña tus ventas - Tour na Plataforma - Hotmart',
        description:
          'Acompañar los resultados de tus ventas en la plataforma Hotmart es muy simple. Si quieres ver cómo andan tus números, este es el lugar correcto. En esta área podrás tener una visión general del rendimiento de tus productos y también crear informes personalizados con las informaciones más relevantes para tu negocio.',
      },
      video: {
        id: 'Z6FczGUjEyQ',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/acompana_tus_vendas.jpg',
        },
      },
    },
    {
      id: 'db0e9af1d7f3',
      locale: 'es-mx',
      tag: 'Herramientas',
      icon: {
        fontAwesome: ['far', 'cog'],
      },
      title: 'Descubre las herramientas del universo Hotmart',
      body: {
        short: `<p>Conoce las soluciones creadas para ayudarte a gestionar tu negocio digital sin necesidad de salir de la plataforma Hotmart.</p>`,
        full: `<p><b>En este video también vas a aprender sobre:</b></p>
        <p>Personalizar tu área de pago con <b>Checkout Builder</b></p>
        <p>Embudo de Ventas</p>
        <p>Gestionar automáticamente tus listas de emails con <b>ListBoss</b></p>`,
      },
      seo: {
        title: 'Descubre las herramientas del universo Hotmart - Tour na Plataforma - Hotmart',
        description:
          'Conoce las soluciones creadas para ayudarte a gestionar tu negocio digital sin necesidad de salir de la plataforma Hotmart.',
      },
      video: {
        id: '-azDyJOL9aA',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/descubre_las_herramientas.jpg',
        },
      },
    },
    {
      id: 'c9b19c1e4a56',
      locale: 'es-mx',
      tag: 'Analytics',
      icon: {
        name: 'analytics',
      },
      title: 'Acompaña tus resultados',
      body: {
        short: `<p>Gráficos e informes personalizados para que acompañes toda la estrategia de tu negocio digital sin salir de la plataforma Hotmart. Con Analytics puedes acompañar de forma rápida todos los datos sobre tus ventas y el rendimiento de tus campañas.</p>`,
      },
      seo: {
        title: 'Acompaña tus resultados - Tour na Plataforma - Hotmart',
        description:
          'Gráficos e informes personalizados para que acompañes toda la estrategia de tu negocio digital sin salir de la plataforma Hotmart. Con Analytics puedes acompañar de forma rápida todos los datos sobre tus ventas y el rendimiento de tus campañas.',
      },
      video: {
        id: 'zlVcCTMXrlw',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/acompana_tus_resultados.jpg',
        },
      },
    },
  ],
}
