// DOCS: https://developers.google.com/search/docs/advanced/structured-data/video
export const setSchemaVideo = payload => {
  const { video } = payload

  const baseSchema = {
    '@type': 'VideoObject',
    name: video.name,
    description: video.description,
    thumbnailUrl: `https://hotmart.com${video.cover.image.src}`,
    uploadDate: video.uploadDate,
    duration: video.duration,
    contentURL: `https://youtu.be/${video.youtubeId}`,
    embedUrl: `https://www.youtube.com/embed/${video.youtubeId}`,
    interactionStatistic: {
      '@type': 'InteractionCounter',
      interactionType: { '@type': 'WatchAction' },
    },
  }

  return baseSchema
}
