import Swiper, { Navigation, Pagination, Autoplay, Mousewheel } from 'swiper'

Swiper.use([Navigation, Pagination, Autoplay, Mousewheel])

// modules styles
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

const DEFAULT_EVENTS = [
  'beforeDestroy',
  'slideChange',
  'slideChangeTransitionStart',
  'slideChangeTransitionEnd',
  'slideNextTransitionStart',
  'slideNextTransitionEnd',
  'slidePrevTransitionStart',
  'slidePrevTransitionEnd',
  'transitionStart',
  'transitionEnd',
  'touchStart',
  'touchMove',
  'touchMoveOpposite',
  'sliderMove',
  'touchEnd',
  'click',
  'tap',
  'doubleTap',
  'imagesReady',
  'progress',
  'reachBeginning',
  'reachEnd',
  'fromEdge',
  'setTranslate',
  'setTransition',
  'resize',
  'observerUpdate',
]

export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      swiper: null,
      classes: {
        wrapperClass: 'swiper-wrapper',
      },
    }
  },

  ready() {
    if (!this.swiper) this.mountInstance()
  },

  mounted() {
    if (!this.swiper) {
      let setClassName = false

      for (const className in this.classes)
        if (this.classes.hasOwnProperty(className))
          if (this.options[className]) {
            setClassName = true
            this.classes[className] = this.options[className]
          }

      setClassName ? this.$nextTick(this.mountInstance) : this.mountInstance()
    }
  },

  activated() {
    this.update()
  },

  updated() {
    this.update()
  },

  beforeDestroy() {
    this.$nextTick(() => {
      if (this.swiper) {
        this.swiper.destroy && this.swiper.destroy(false, false)

        delete this.swiper
      }
    })
  },

  methods: {
    mountInstance() {
      if (Object.keys(this.options).length === 0) return

      this.swiper = new Swiper(this.$el, this.options)

      this.bindEvents()

      this.$emit('ready', this.swiper)
    },

    bindEvents() {
      DEFAULT_EVENTS.forEach(eventName => {
        this.swiper.on(eventName, () => {
          const eventNameInKebabCase = eventName.replace(/([A-Z])/g, '-$1')

          this.$emit(eventName, ...arguments)

          if (eventName !== eventNameInKebabCase) this.$emit(eventNameInKebabCase, ...arguments)
        })
      })
    },

    update() {
      if (this.swiper) {
        this.swiper.update && this.swiper.update()
        this.swiper.navigation && this.swiper.navigation.update()
        this.swiper.pagination && this.swiper.pagination.render()
        this.swiper.pagination && this.swiper.pagination.update()
      }
    },
  },
}
