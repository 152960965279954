import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b5ad06a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5a7d6460 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _2ba2f5a5 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _4619165e = () => interopDefault(import('../pages/affiliates.vue' /* webpackChunkName: "pages/affiliates" */))
const _6f5c9e4e = () => interopDefault(import('../pages/club.vue' /* webpackChunkName: "pages/club" */))
const _cc1ac1e2 = () => interopDefault(import('../pages/hotmart-app.vue' /* webpackChunkName: "pages/hotmart-app" */))
const _a69796be = () => interopDefault(import('../pages/is-hotmart-for-me.vue' /* webpackChunkName: "pages/is-hotmart-for-me" */))
const _2480c4d3 = () => interopDefault(import('../pages/lp-producer.vue' /* webpackChunkName: "pages/lp-producer" */))
const _4cbcac9c = () => interopDefault(import('../pages/members-area/index.vue' /* webpackChunkName: "pages/members-area/index" */))
const _20721576 = () => interopDefault(import('../pages/payments.vue' /* webpackChunkName: "pages/payments" */))
const _2ba82d5e = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _4b19e98e = () => interopDefault(import('../pages/producers.vue' /* webpackChunkName: "pages/producers" */))
const _2371b49c = () => interopDefault(import('../pages/solutions.vue' /* webpackChunkName: "pages/solutions" */))
const _4321551a = () => interopDefault(import('../pages/tour/index.vue' /* webpackChunkName: "pages/tour/index" */))
const _405d2acd = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _c2e37ef4 = () => interopDefault(import('../pages/members-area/thank-you.vue' /* webpackChunkName: "pages/members-area/thank-you" */))
const _04d5cbc9 = () => interopDefault(import('../pages/members-area/paid-media.vue' /* webpackChunkName: "pages/members-area/paid-media" */))
const _30aa189b = () => interopDefault(import('../pages/tour/_id.vue' /* webpackChunkName: "pages/tour/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _4b5ad06a,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/es",
    component: _4b5ad06a,
    pathToRegexpOptions: {"strict":true},
    name: "index___es"
  }, {
    path: "/es-co",
    component: _4b5ad06a,
    pathToRegexpOptions: {"strict":true},
    name: "index___es-co"
  }, {
    path: "/es-es",
    component: _4b5ad06a,
    pathToRegexpOptions: {"strict":true},
    name: "index___es-es"
  }, {
    path: "/es-mx",
    component: _4b5ad06a,
    pathToRegexpOptions: {"strict":true},
    name: "index___es-mx"
  }, {
    path: "/fr",
    component: _4b5ad06a,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr"
  }, {
    path: "/it",
    component: _4b5ad06a,
    pathToRegexpOptions: {"strict":true},
    name: "index___it"
  }, {
    path: "/ja",
    component: _4b5ad06a,
    pathToRegexpOptions: {"strict":true},
    name: "index___ja"
  }, {
    path: "/pt-br",
    component: _4b5ad06a,
    pathToRegexpOptions: {"strict":true},
    name: "index___pt-br"
  }, {
    path: "/en/404",
    component: _5a7d6460,
    pathToRegexpOptions: {"strict":true},
    name: "404___en"
  }, {
    path: "/en/about",
    component: _2ba2f5a5,
    pathToRegexpOptions: {"strict":true},
    name: "about___en"
  }, {
    path: "/en/affiliates",
    component: _4619165e,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___en"
  }, {
    path: "/en/club",
    component: _6f5c9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "club___en"
  }, {
    path: "/en/hotmart-app",
    component: _cc1ac1e2,
    pathToRegexpOptions: {"strict":true},
    name: "hotmart-app___en"
  }, {
    path: "/en/is-hotmart-for-me",
    component: _a69796be,
    pathToRegexpOptions: {"strict":true},
    name: "is-hotmart-for-me___en"
  }, {
    path: "/en/lp-producer",
    component: _2480c4d3,
    pathToRegexpOptions: {"strict":true},
    name: "lp-producer___en"
  }, {
    path: "/en/members-area",
    component: _4cbcac9c,
    pathToRegexpOptions: {"strict":true},
    name: "members-area___en"
  }, {
    path: "/en/payments",
    component: _20721576,
    pathToRegexpOptions: {"strict":true},
    name: "payments___en"
  }, {
    path: "/en/pricing",
    component: _2ba82d5e,
    pathToRegexpOptions: {"strict":true},
    name: "pricing___en"
  }, {
    path: "/en/producers",
    component: _4b19e98e,
    pathToRegexpOptions: {"strict":true},
    name: "producers___en"
  }, {
    path: "/en/solutions",
    component: _2371b49c,
    pathToRegexpOptions: {"strict":true},
    name: "solutions___en"
  }, {
    path: "/en/tour",
    component: _4321551a,
    pathToRegexpOptions: {"strict":true},
    name: "tour___en"
  }, {
    path: "/es-co/404",
    component: _5a7d6460,
    pathToRegexpOptions: {"strict":true},
    name: "404___es-co"
  }, {
    path: "/es-co/afiliados",
    component: _4619165e,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___es-co"
  }, {
    path: "/es-co/app-de-hotmart",
    component: _cc1ac1e2,
    pathToRegexpOptions: {"strict":true},
    name: "hotmart-app___es-co"
  }, {
    path: "/es-co/club",
    component: _6f5c9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "club___es-co"
  }, {
    path: "/es-co/lp-producer",
    component: _2480c4d3,
    pathToRegexpOptions: {"strict":true},
    name: "lp-producer___es-co"
  }, {
    path: "/es-co/pagos",
    component: _20721576,
    pathToRegexpOptions: {"strict":true},
    name: "payments___es-co"
  }, {
    path: "/es-co/productores",
    component: _4b19e98e,
    pathToRegexpOptions: {"strict":true},
    name: "producers___es-co"
  }, {
    path: "/es-co/sobre",
    component: _2ba2f5a5,
    pathToRegexpOptions: {"strict":true},
    name: "about___es-co"
  }, {
    path: "/es-co/soluciones",
    component: _2371b49c,
    pathToRegexpOptions: {"strict":true},
    name: "solutions___es-co"
  }, {
    path: "/es-co/tour",
    component: _4321551a,
    pathToRegexpOptions: {"strict":true},
    name: "tour___es-co"
  }, {
    path: "/es-es/404",
    component: _5a7d6460,
    pathToRegexpOptions: {"strict":true},
    name: "404___es-es"
  }, {
    path: "/es-es/afiliados",
    component: _4619165e,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___es-es"
  }, {
    path: "/es-es/app-de-hotmart",
    component: _cc1ac1e2,
    pathToRegexpOptions: {"strict":true},
    name: "hotmart-app___es-es"
  }, {
    path: "/es-es/club",
    component: _6f5c9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "club___es-es"
  }, {
    path: "/es-es/lp-producer",
    component: _2480c4d3,
    pathToRegexpOptions: {"strict":true},
    name: "lp-producer___es-es"
  }, {
    path: "/es-es/pagos",
    component: _20721576,
    pathToRegexpOptions: {"strict":true},
    name: "payments___es-es"
  }, {
    path: "/es-es/precios",
    component: _2ba82d5e,
    pathToRegexpOptions: {"strict":true},
    name: "pricing___es-es"
  }, {
    path: "/es-es/productores",
    component: _4b19e98e,
    pathToRegexpOptions: {"strict":true},
    name: "producers___es-es"
  }, {
    path: "/es-es/sobre",
    component: _2ba2f5a5,
    pathToRegexpOptions: {"strict":true},
    name: "about___es-es"
  }, {
    path: "/es-es/soluciones",
    component: _2371b49c,
    pathToRegexpOptions: {"strict":true},
    name: "solutions___es-es"
  }, {
    path: "/es-es/tour",
    component: _4321551a,
    pathToRegexpOptions: {"strict":true},
    name: "tour___es-es"
  }, {
    path: "/es-mx/404",
    component: _5a7d6460,
    pathToRegexpOptions: {"strict":true},
    name: "404___es-mx"
  }, {
    path: "/es-mx/afiliados",
    component: _4619165e,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___es-mx"
  }, {
    path: "/es-mx/app-de-hotmart",
    component: _cc1ac1e2,
    pathToRegexpOptions: {"strict":true},
    name: "hotmart-app___es-mx"
  }, {
    path: "/es-mx/club",
    component: _6f5c9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "club___es-mx"
  }, {
    path: "/es-mx/lp-producer",
    component: _2480c4d3,
    pathToRegexpOptions: {"strict":true},
    name: "lp-producer___es-mx"
  }, {
    path: "/es-mx/pagos",
    component: _20721576,
    pathToRegexpOptions: {"strict":true},
    name: "payments___es-mx"
  }, {
    path: "/es-mx/productores",
    component: _4b19e98e,
    pathToRegexpOptions: {"strict":true},
    name: "producers___es-mx"
  }, {
    path: "/es-mx/sobre",
    component: _2ba2f5a5,
    pathToRegexpOptions: {"strict":true},
    name: "about___es-mx"
  }, {
    path: "/es-mx/soluciones",
    component: _2371b49c,
    pathToRegexpOptions: {"strict":true},
    name: "solutions___es-mx"
  }, {
    path: "/es-mx/tour",
    component: _4321551a,
    pathToRegexpOptions: {"strict":true},
    name: "tour___es-mx"
  }, {
    path: "/es/404",
    component: _5a7d6460,
    pathToRegexpOptions: {"strict":true},
    name: "404___es"
  }, {
    path: "/es/afiliados",
    component: _4619165e,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___es"
  }, {
    path: "/es/app-de-hotmart",
    component: _cc1ac1e2,
    pathToRegexpOptions: {"strict":true},
    name: "hotmart-app___es"
  }, {
    path: "/es/area-de-miembros",
    component: _4cbcac9c,
    pathToRegexpOptions: {"strict":true},
    name: "members-area___es"
  }, {
    path: "/es/club",
    component: _6f5c9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "club___es"
  }, {
    path: "/es/lp-producer",
    component: _2480c4d3,
    pathToRegexpOptions: {"strict":true},
    name: "lp-producer___es"
  }, {
    path: "/es/pagos",
    component: _20721576,
    pathToRegexpOptions: {"strict":true},
    name: "payments___es"
  }, {
    path: "/es/productores",
    component: _4b19e98e,
    pathToRegexpOptions: {"strict":true},
    name: "producers___es"
  }, {
    path: "/es/sobre",
    component: _2ba2f5a5,
    pathToRegexpOptions: {"strict":true},
    name: "about___es"
  }, {
    path: "/es/soluciones",
    component: _2371b49c,
    pathToRegexpOptions: {"strict":true},
    name: "solutions___es"
  }, {
    path: "/es/tour",
    component: _4321551a,
    pathToRegexpOptions: {"strict":true},
    name: "tour___es"
  }, {
    path: "/fr/404",
    component: _5a7d6460,
    pathToRegexpOptions: {"strict":true},
    name: "404___fr"
  }, {
    path: "/fr/affilies",
    component: _4619165e,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___fr"
  }, {
    path: "/fr/club",
    component: _6f5c9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "club___fr"
  }, {
    path: "/fr/lp-producer",
    component: _2480c4d3,
    pathToRegexpOptions: {"strict":true},
    name: "lp-producer___fr"
  }, {
    path: "/fr/paiements",
    component: _20721576,
    pathToRegexpOptions: {"strict":true},
    name: "payments___fr"
  }, {
    path: "/fr/prix",
    component: _2ba82d5e,
    pathToRegexpOptions: {"strict":true},
    name: "pricing___fr"
  }, {
    path: "/fr/producteurs",
    component: _4b19e98e,
    pathToRegexpOptions: {"strict":true},
    name: "producers___fr"
  }, {
    path: "/fr/propos-de-nous",
    component: _2ba2f5a5,
    pathToRegexpOptions: {"strict":true},
    name: "about___fr"
  }, {
    path: "/fr/solutions",
    component: _2371b49c,
    pathToRegexpOptions: {"strict":true},
    name: "solutions___fr"
  }, {
    path: "/fr/tour",
    component: _4321551a,
    pathToRegexpOptions: {"strict":true},
    name: "tour___fr"
  }, {
    path: "/fr/zone-des-membres",
    component: _4cbcac9c,
    pathToRegexpOptions: {"strict":true},
    name: "members-area___fr"
  }, {
    path: "/it/404",
    component: _5a7d6460,
    pathToRegexpOptions: {"strict":true},
    name: "404___it"
  }, {
    path: "/it/affiliazione",
    component: _4619165e,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___it"
  }, {
    path: "/it/chi-siamo",
    component: _2ba2f5a5,
    pathToRegexpOptions: {"strict":true},
    name: "about___it"
  }, {
    path: "/it/club",
    component: _6f5c9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "club___it"
  }, {
    path: "/it/funzionalita",
    component: _2371b49c,
    pathToRegexpOptions: {"strict":true},
    name: "solutions___it"
  }, {
    path: "/it/lp-producer",
    component: _2480c4d3,
    pathToRegexpOptions: {"strict":true},
    name: "lp-producer___it"
  }, {
    path: "/ja/%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6",
    component: _2ba2f5a5,
    pathToRegexpOptions: {"strict":true},
    name: "about___ja"
  }, {
    path: "/ja/%E3%82%A2%E3%83%95%E3%82%A3%E3%83%AA%E3%82%A8%E3%82%A4%E3%82%BF%E3%83%BC",
    component: _4619165e,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___ja"
  }, {
    path: "/ja/%E3%82%BD%E3%83%AA%E3%83%A5%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3",
    component: _2371b49c,
    pathToRegexpOptions: {"strict":true},
    name: "solutions___ja"
  }, {
    path: "/ja/404",
    component: _5a7d6460,
    pathToRegexpOptions: {"strict":true},
    name: "404___ja"
  }, {
    path: "/ja/club",
    component: _6f5c9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "club___ja"
  }, {
    path: "/ja/lp-producer",
    component: _2480c4d3,
    pathToRegexpOptions: {"strict":true},
    name: "lp-producer___ja"
  }, {
    path: "/pt-br/404",
    component: _5a7d6460,
    pathToRegexpOptions: {"strict":true},
    name: "404___pt-br"
  }, {
    path: "/pt-br/afiliados",
    component: _4619165e,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___pt-br"
  }, {
    path: "/pt-br/app-da-hotmart",
    component: _cc1ac1e2,
    pathToRegexpOptions: {"strict":true},
    name: "hotmart-app___pt-br"
  }, {
    path: "/pt-br/area-de-membros",
    component: _4cbcac9c,
    pathToRegexpOptions: {"strict":true},
    name: "members-area___pt-br"
  }, {
    path: "/pt-br/club",
    component: _6f5c9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "club___pt-br"
  }, {
    path: "/pt-br/lp-producer",
    component: _2480c4d3,
    pathToRegexpOptions: {"strict":true},
    name: "lp-producer___pt-br"
  }, {
    path: "/pt-br/mapa-do-site",
    component: _405d2acd,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___pt-br"
  }, {
    path: "/pt-br/pagamentos",
    component: _20721576,
    pathToRegexpOptions: {"strict":true},
    name: "payments___pt-br"
  }, {
    path: "/pt-br/produtores",
    component: _4b19e98e,
    pathToRegexpOptions: {"strict":true},
    name: "producers___pt-br"
  }, {
    path: "/pt-br/sobre-nos",
    component: _2ba2f5a5,
    pathToRegexpOptions: {"strict":true},
    name: "about___pt-br"
  }, {
    path: "/pt-br/solucoes",
    component: _2371b49c,
    pathToRegexpOptions: {"strict":true},
    name: "solutions___pt-br"
  }, {
    path: "/pt-br/tour",
    component: _4321551a,
    pathToRegexpOptions: {"strict":true},
    name: "tour___pt-br"
  }, {
    path: "/en/members-area/thank-you",
    component: _c2e37ef4,
    pathToRegexpOptions: {"strict":true},
    name: "members-area-thank-you___en"
  }, {
    path: "/es/area-de-miembros/gracias",
    component: _c2e37ef4,
    pathToRegexpOptions: {"strict":true},
    name: "members-area-thank-you___es"
  }, {
    path: "/pt-br/area-de-membros/midias-pagas",
    component: _04d5cbc9,
    pathToRegexpOptions: {"strict":true},
    name: "members-area-paid-media___pt-br"
  }, {
    path: "/pt-br/area-de-membros/obrigado",
    component: _c2e37ef4,
    pathToRegexpOptions: {"strict":true},
    name: "members-area-thank-you___pt-br"
  }, {
    path: "/en/tour/:id",
    component: _30aa189b,
    pathToRegexpOptions: {"strict":true},
    name: "tour-id___en"
  }, {
    path: "/es-co/tour/:id",
    component: _30aa189b,
    pathToRegexpOptions: {"strict":true},
    name: "tour-id___es-co"
  }, {
    path: "/es-es/tour/:id",
    component: _30aa189b,
    pathToRegexpOptions: {"strict":true},
    name: "tour-id___es-es"
  }, {
    path: "/es-mx/tour/:id",
    component: _30aa189b,
    pathToRegexpOptions: {"strict":true},
    name: "tour-id___es-mx"
  }, {
    path: "/es/tour/:id",
    component: _30aa189b,
    pathToRegexpOptions: {"strict":true},
    name: "tour-id___es"
  }, {
    path: "/fr/tour/:id",
    component: _30aa189b,
    pathToRegexpOptions: {"strict":true},
    name: "tour-id___fr"
  }, {
    path: "/pt-br/tour/:id",
    component: _30aa189b,
    pathToRegexpOptions: {"strict":true},
    name: "tour-id___pt-br"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
