export const documentWidth = () => {
  if (process.client) {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  }

  return 0
}

export const documentHeight = () => {
  if (process.client) {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  }

  return 0
}

export const outerWidth = el => {
  let width = el.offsetWidth
  const style = window.getComputedStyle(el)

  width += parseInt(style.marginLeft) + parseInt(style.marginRight)

  return width
}

export const outerHeight = el => {
  let height = el.offsetHeight
  const style = window.getComputedStyle(el)

  height += parseInt(style.marginTop) + parseInt(style.marginBottom)

  return height
}

export default {
  documentWidth,
  documentHeight,
  outerWidth,
  outerHeight,
}
