// UTILITY
import { checkAndThenAddProps } from '../../../../../../utils/checkAndThenAddProps'

export const setSharedOG = payload => {
  const { locale = 'pt-BR', url, image, title, description, meta = {}, defaults } = payload
  const { images } = defaults

  const objToUpdate = {
    'og:locale': locale,
    'og:type': 'website',
    'og:site_name': 'Hotmart',
    'og:url': url,
    'og:image': image ? image : images.facebook,
  }

  return {
    ...checkAndThenAddProps({
      objects: { objToCheck: meta, objToUpdate },
      props: [
        { key: 'og:title', value: title },
        { key: 'og:description', value: description },
      ],
    }),
  }
}
