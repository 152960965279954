import { getDeviceSize } from '~/utils/device'

export default {
  data() {
    return {
      isDesktop: null,
    }
  },

  mounted() {
    this.checkDeviceSize()
  },

  methods: {
    checkDeviceSize() {
      const size = getDeviceSize()

      if (/desktop(?:\-(?:xl|[lm]))?/.test(size)) {
        this.isDesktop = true
      } else {
        this.isDesktop = false
      }
    },
  },
}
