import components from './en/components'
import seo from './en/seo'
import pages from './en/pages'
import validation from './en/validation/index.json'

export default {
  components,
  seo,
  pages,
  validation,
}
