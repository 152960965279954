export default {
  data: [
    {
      id: 'd5744d344f36',
      locale: 'en',
      tag: 'Main dashboard',
      icon: {
        fontAwesome: ['far', 'tasks'],
      },
      title: 'How to access the main information',
      body: {
        short: `<p>Your main information is displayed right after login. In the Main Dashboard you can see your total revenue, the refund rate, as well as a graph with several filters for you to monitor your sales, in an easy, quick manner.</p>`,
      },
      seo: {
        title: 'How to access the main information - Platform Tour - Hotmart',
        description:
          'Your main information is displayed right after login. In the Main Dashboard you can see your total revenue, the refund rate, as well as a graph with several filters for you to monitor your sales, in an easy, quick manner.',
      },
      video: {
        id: 'cwFaPV0S3jo',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/main_information.jpg',
        },
      },
    },
    {
      id: '6dc788efd6f9',
      locale: 'en',
      tag: 'Products',
      icon: {
        fontAwesome: ['fal', 'cube'],
      },
      title: 'Manage your product',
      body: {
        short: `<p>In a few minutes you can register your product on the Hotmart platform and start selling. The first step is to fill in the necessary information for it to be sent for review and after approval be made available on the market.</p>`,
        full: `<p><b>In this video you’ll learn more about:</b></p>
        <p>Types of Payment</p>
        <p>Pricing</p>
        <p>How to manage your product</p>
        <p>Members Area</p>
        <p>How to create modules</p>
        <p>Hotmart video player</p>
        <p>Subtitles</p>
        <p>Users section</p>
        <p>Custom templates</p>
        <p>Affiliate program</p>`,
      },
      seo: {
        title: 'Manage your product - Platform Tour - Hotmart',
        description:
          'In a few minutes you can register your product on the Hotmart platform and start selling. The first step is to fill in the necessary information for it to be sent for review and after approval be made available on the market.',
      },
      video: {
        id: 'YE-2dSLjcnI',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/manage_your_product.jpg',
        },
      },
    },
    {
      id: 'd26a7351c5bf',
      locale: 'en',
      tag: 'Market',
      icon: {
        fontAwesome: ['fal', 'th'],
      },
      title: 'Get to know the market tabs',
      body: {
        short: `<p>Products that fulfill the platform’s criteria are exhibited on the Hotmart Market. </p>`,
        full: `<p><b>In this video you’ll also learn about:</b></p>
        <p>Types of link tracking - <b>Hotlinks</b></p>
        <p>Promotional materials</p>
        <p>Affiliation Models</p>`,
      },
      seo: {
        title: 'Get to know the market tabs - Platform Tour - Hotmart',
        description:
          'If your goal is to work as an Affiliate, here you’ll find the best products for your sales strategy. ',
      },
      video: {
        id: 'Oe3sCIXWc8o',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/market_tabs.jpg',
        },
      },
    },
    {
      id: '42ccbcd4b160',
      locale: 'en',
      tag: 'Tools',
      icon: {
        fontAwesome: ['far', 'cog'],
      },
      title: 'Learn more about the tools in the Hotmart universe',
      body: {
        short: `<p>Get to know the solutions created to help you manage your digital business without having to leave the Hotmart platform. </p>`,
        full: `<p><b>In this video you’ll also learn about:</b></p>
        <p>Payment page with <b>Checkout Builder</b></p>
        <p>Automatic mailing lists management with <b>ListBoss</b></p>
        <p>Link Manager</p>
        <p>Buyer survey - Hotmart Ask</p>`,
      },
      seo: {
        title: 'Learn more about the tools in the Hotmart universe - Platform Tour - Hotmart',
        description:
          'Get to know the solutions created to help you manage your digital business without having to leave the Hotmart platform. ',
      },
      video: {
        id: 'YixWESoyLCg',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/hotmart_tools.jpg',
        },
      },
    },
    {
      id: '9f9c2740e8b1',
      locale: 'en',
      tag: 'Sales',
      icon: {
        fontAwesome: ['far', 'shopping-cart'],
      },
      title: 'Monitor your sales',
      body: {
        short: `<p>Tracking your sales results on the Hotmart platform is very simple. If you want to see how well your product is doing, you’re in the right place. Here you have an overview of your products’ performance and you can also generate customized reports with the most relevant information for your business.</p>`,
      },
      seo: {
        title: 'Monitor your sales - Platform Tour - Hotmart',
        description:
          'Tracking your sales results on the Hotmart platform is very simple. If you want to see how well your product is doing, you’re in the right place. Here you have an overview of your products’ performance and you can also generate customized reports with the most relevant information for your business.',
      },
      video: {
        id: 'TmuiyIjBkGU',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/monitor_your_sales.jpg',
        },
      },
    },
    {
      id: 'adddf70b008c',
      locale: 'en',
      tag: 'My Affiliates',
      icon: {
        name: 'affiliate',
      },
      title: 'Manage your Affiliates',
      body: {
        short: `<p>The Hotmart Platform has an exclusive area for Producers to manage their products’ Affiliates. In the menu "My Affiliates" it is possible to share specific information and even send messages to each Affiliate.</p>`,
      },
      seo: {
        title: 'Manage your Affiliates - Platform Tour - Hotmart',
        description:
          'The Hotmart Platform has an exclusive area for Producers to manage their products’ Affiliates. In the menu "My Affiliates" it is possible to share specific information and even send messages to each Affiliate.',
      },
      video: {
        id: 'TiZDqCA-XdE',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/manage_your_affiliates.jpg',
        },
      },
    },
    {
      id: 'b807c20e41a0',
      locale: 'en',
      tag: 'Analytics',
      icon: {
        name: 'analytics',
      },
      title: 'Follow your results',
      body: {
        short: `<p>Custom graphics and reports for you to follow the effectiveness of your digital business strategy without having to leave the Hotmart platform. With Analytics you can track your sales and campaign data, and performance</p>`,
      },
      seo: {
        title: 'Follow your results - Platform Tour - Hotmart',
        description:
          'Custom graphics and reports for you to follow the effectiveness of your digital business strategy without having to leave the Hotmart platform. With Analytics you can track your sales and campaign data, and performance',
      },
      video: {
        id: 'XeHgOpeSHag',
        thumbnail: {
          src: '/static/app-hotmart/images/tour/follow_your_results.jpg',
        },
      },
    },
  ],
}
