import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=f1dd0728&scoped=true"
import script from "./Breadcrumb.js?vue&type=script&lang=js&external"
export * from "./Breadcrumb.js?vue&type=script&lang=js&external"
import style0 from "./Breadcrumb.scss?vue&type=style&index=0&id=f1dd0728&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1dd0728",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotLink: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Link/Link.vue').default})
