// UTILITY
import { checkAndThenAddProps } from '../../../../../../utils/checkAndThenAddProps'

export const setSharedMeta = payload => {
  const { description, meta = {}, locale = '' } = payload
  const robots = locale === 'pt-BR' ? { robots: 'index, follow' } : ''

  const objToUpdate = {
    ...robots,
    viewport: 'width=device-width, initial-scale=1',
    author: 'Hotmart',
    'wot-verification': 'e8319bcf68058b9f1295',
    'mobile-web-app-capable': 'yes',
    'apple-mobile-web-app-capable': 'yes',
    'apple-mobile-web-app-status-bar-style': 'black',
    'apple-mobile-web-app-title': 'Hotmart',
    'msapplication-TileColor': '#ffffff',
    'msapplication-config': '/images/icons/browserconfig.xml',
    'theme-color': '#ffffff',
  }

  return {
    ...checkAndThenAddProps({
      objects: { objToCheck: meta, objToUpdate },
      props: [{ key: 'description', value: description }],
    }),
  }
}
